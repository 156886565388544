import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField } from "@mui/material";
import React from "react";
import HttpService from "../../services/HttpService";
import { Team, TeamPlayerPosition, TeamPlayerSuggest } from "../../types/Saison";
import DialogProgressComponent from "../common/DialogProgressComponent";
import PositionIcon from "../common/PositionIconComponent";

function TeamPlayerSuggestDialogComponent(props: {
    open:boolean,
    onClose: () => void;
    teamPlayerSuggest?:TeamPlayerSuggest,
    teams:Team[],
}) {

    const { onClose } = props
    const [ id, setId ] = React.useState("")
    const [ name, setName ] = React.useState("")
    const [ position, setPosition ] = React.useState(TeamPlayerPosition.goalkeeper)
    const [ number, setNumber ] = React.useState(0);
    const [ teamId, setTeamId] = React.useState("")
    const [ kickerPrice, setKickerPrice ] = React.useState(0.0)

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if(props.teamPlayerSuggest) {
            setId(props.teamPlayerSuggest?.id)
            setName(props.teamPlayerSuggest?.name)
            setPosition(props.teamPlayerSuggest.position)
            setTeamId(props.teamPlayerSuggest.teamId === null ? "" : props.teamPlayerSuggest.teamId)
            setKickerPrice(props.teamPlayerSuggest.kickerPrice)
            setNumber(props.teamPlayerSuggest.number ? props.teamPlayerSuggest.number : 0)
        } else {
            reset()
        }
    },[props.teamPlayerSuggest])
    
    const handleChangePosition = (teamPlayerPosition:TeamPlayerPosition | string) => {
        setPosition(teamPlayerPosition as TeamPlayerPosition)
    }

    const handleCancel = () => {
        onClose();
        reset();
    }

    const handleDelete = () => {
        HttpService.delete("/SaisonAPI/TeamPlayerSuggest", {
            params: { teamPlayerSuggestId: props.teamPlayerSuggest?.id }
            }).then(res => {if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              console.log(res.data);
            }
            onClose();
            reset();
          });    
    }

    const handleOk = () => {
        setLoading(true)
        HttpService.post("/SaisonAPI/TeamPlayerSuggest", {
            id: id,
            name: name,
            teamId: teamId,
            position: position,
            kickerPrice: kickerPrice,
            number: number,
            saisonId: props.teams[0].saisonId
          }).then(res => {
            if(res.status === 200) {
                handleCancel();      
                setLoading(false);
            } 
          })
    }

    const reset = () => {
        setId("")
        setName("")
        setPosition(TeamPlayerPosition.goalkeeper)
        setTeamId("")
        setKickerPrice(0.0)
        setNumber(0)
        setLoading(false)
    }

    return(
        <TeamPlayerDialog open={props.open}>
            <DialogTitle display={'flex'} flexDirection={'row'}>
                {props.teamPlayerSuggest && (
                    <Box className='teamlogo-fit' minWidth={40} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.teams?.find(s => s.id === props.teamPlayerSuggest?.teamId)?.imageLogoPath + ')'}} />
                )}
                <Box marginLeft={2}>{name.length === 0 ? "Neuer Spieler" : name}</Box>
            </DialogTitle>
            {loading && (
                <DialogProgressComponent delay={1000} />
            )}
            <DialogContent dividers>
                <Box component="form">
                    <TextField sx={{marginTop: 3}} required={true} size="small" fullWidth id="outlined-basic" label="NAME" variant="outlined" value={name} onChange={e => setName(e.target.value)} />
                    <FormControl sx={{marginTop: 3}} fullWidth>
                        <InputLabel>POSITION</InputLabel>
                        <Select fullWidth={true} value={position} size="small" label="POSITION" onChange={e => handleChangePosition(e.target.value)}>
                            <MenuItem value={TeamPlayerPosition.goalkeeper}>
                                <Grid container wrap="nowrap">
                                    <Grid item marginRight={'5px'}>
                                        <PositionIcon position={TeamPlayerPosition.goalkeeper}/>
                                    </Grid>
                                    <Grid item>
                                        TOR
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value={TeamPlayerPosition.defender}>
                                <Grid container wrap="nowrap">
                                    <Grid item marginRight={'5px'}>
                                        <PositionIcon position={TeamPlayerPosition.defender}/>
                                    </Grid>
                                    <Grid item>
                                        ABWEHR
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value={TeamPlayerPosition.midfielder}>
                                <Grid container wrap="nowrap">
                                    <Grid item marginRight={'5px'}>
                                        <PositionIcon position={TeamPlayerPosition.midfielder}/>
                                    </Grid>
                                    <Grid item>
                                        MITTELFELD
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value={TeamPlayerPosition.striker}>
                                <Grid container wrap="nowrap">
                                    <Grid item marginRight={'5px'}>
                                        <PositionIcon position={TeamPlayerPosition.striker}/>
                                    </Grid>
                                    <Grid item>
                                        STURM
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{marginTop: 3}} fullWidth>
                        <InputLabel required={true}>VEREIN</InputLabel>
                        <Select fullWidth={true} value={teamId} size="small" label="VEREIN" onChange={e => setTeamId(e.target.value)}>
                            {props.teams?.sort((a,b) => a.name.localeCompare(b.name)).map((Team) => (
                                <MenuItem key={Team.id} value={Team.id}>{Team.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField sx={{marginTop: 3}} type="number" size="small" fullWidth id="outlined-basic" label="NUMMER" variant="outlined" defaultValue={props.teamPlayerSuggest?.number} onFocus={e => e.target.select()} onChange={e => setNumber(Number(e.target.value))} />
                    <TextField sx={{marginTop: 3}} type="number" size="small" fullWidth id="outlined-basic" label="PREIS" variant="outlined" defaultValue={props.teamPlayerSuggest?.kickerPrice} onFocus={e => e.target.select()} onChange={e => setKickerPrice(Number(e.target.value))} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete} disabled={!id}>LÖSCHEN</Button>
                <Button onClick={handleCancel} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleOk} disabled={loading || !name || !teamId}>ÜBERNEHMEN</Button>
            </DialogActions>
        </TeamPlayerDialog>      
    )
}
export default TeamPlayerSuggestDialogComponent

const TeamPlayerDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))