import { ChatBubble, ChatBubbleOutline, Edit } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Fade, Grid, SxProps, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";
import Loader from "../components/common/Loader";
import LineUpItemCommentDialogComponent from "../components/matchDay/LineUpItemCommentDialogComponent";
import TeamPlayerDialogComponent from "../components/saison/TeamPlayerDialogComponent";
import HttpService from "../services/HttpService";
import { AllocRole, SaisonState, TeamPlayer, TeamPlayersModel } from "../types/Saison";
import TeamPlayerStatisticsDialogComponent from "../components/saison/TeamPlayerStatisticsDialogComponent";
import ShirtComponent from "../components/common/ShirtComponent";
import moment from "moment";

const TeamPlayersPage = () => {

    let { id } = useParams();
    
    const [teamPlayersModel, setTeamPlayersModel] = React.useState<TeamPlayersModel>(); 
    const [openLineUpItemCommentDialog, setOpenLineUpItemCommentDialog] = React.useState(false);
    const [selectedTeamPlayer, setSelectedTeamPlayer] = React.useState<TeamPlayer>();
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);
    const [reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);

    const ctx = React.useContext(AppContext)

    React.useEffect(function() {

        window.scrollTo(0, 0);

        HttpService.get('/SaisonAPI/TeamPlayers', {
            params: { saisonId: id }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }
  
                ctx?.setUserSaisonAlloc(res.data.userSaisonAlloc)
                ctx?.setSaison(res.data.saison)

                setTeamPlayersModel(res.data);  
            });   
    }, [id, reducerValue])

    // Sortierung der Manager (Ich zuerst, dann Sortierung nach Name)
    if(teamPlayersModel !== undefined) {

        teamPlayersModel.userSaisonAllocs.sort((a, b) => {
            if (a.id === teamPlayersModel.userSaisonAlloc.id) return -1;
            return 0;
        });
    
        teamPlayersModel.userSaisonAllocs.sort((a, b) => {
            if (a !== teamPlayersModel.userSaisonAllocs[0] && b !== teamPlayersModel.userSaisonAllocs[0]) {
                if (a.playerName.toUpperCase() > b.playerName.toUpperCase()) return 1;
                if (a.playerName.toUpperCase() < b.playerName.toUpperCase()) return -1;
            }
            return 0;
        });
    }

    const sxProps:SxProps = {
        display: {
            xs: 'none',
            sm: 'table-cell'
        },
        padding: '0px'
    }

    const handleOpenCommentDialog = (teamPlayer:TeamPlayer) => {
        setSelectedTeamPlayer(teamPlayer)
        setOpenLineUpItemCommentDialog(true)
    };

    const handleCloseCommentDialog = () => {
        setOpenLineUpItemCommentDialog(false)
    }

    const handleEditDialogClose = () => {
        setOpenEditDialog(false)
        forceUpdate()
    }

    const handleOpenEditDialog = (teamPlayer:TeamPlayer) => {
        setSelectedTeamPlayer(teamPlayer)
        setOpenEditDialog(true)
    }

    const handleOpenTeamPlayerDetails = (teamPlayer?:TeamPlayer) => {
        setSelectedTeamPlayer(teamPlayer)
        setOpenDetailsDialog(true)
    }

    const handleDetailsDialogClose = () => {
        setOpenDetailsDialog(false)
    }

    return(
        <div>
            {teamPlayersModel && (
                <Fade in>
                    <div>
                        <Card sx={{marginBottom: 2}}>
                            <CardHeader title="WECHSELBÖRSE"></CardHeader>
                            <CardContent>
                                <TableContainer sx={{marginBottom: 2}}>
                                    <Table size="small" sx={{marginBottom: 0.5}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={sxProps}>POS</TableCell>
                                                <TableCell sx={sxProps}>#</TableCell>
                                                <TableCell>NAME</TableCell>
                                                <TableCell sx={sxProps} align='center'>VEREIN</TableCell>
                                                <TableCell sx={sxProps} align='right'>PREIS</TableCell>
                                                <TableCell>MANAGER</TableCell>
                                                <TableCell>NEUER SPIELER</TableCell>
                                                <TableCell sx={sxProps} align='center'>VEREIN</TableCell>
                                                <TableCell sx={sxProps}>NEUER SPIELER AB</TableCell>
                                                <TableCell sx={sxProps}>INFO</TableCell>
                                                {teamPlayersModel.userSaisonAlloc.allocRole === AllocRole.owner && teamPlayersModel.saison.state !== SaisonState.Finished && (
                                                        <TableCell align='center'>...</TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {teamPlayersModel?.teamPlayers.filter(s => s.active === false).sort(
                                            (a,b) => {
                                                // if(a.position > b.position) return 1;
                                                // if(a.position < b.position) return -1;
                                                // else {
                                                //     if(a.info === null) return -1;
                                                //     if(a.info > b.info) return 1;
                                                //     if(a.info < b.info) return -1;
                                                //     return 0;
                                                // }
                                                if(!a.sequence)
                                                    a.sequence = 0

                                                if(!b.sequence)
                                                    b.sequence = 0

                                                if(a.position > b.position) return 1;
                                                if(a.position < b.position) return -1;
                                                else {
                                                    if(a.sequence > b.sequence) return 1
                                                    if(a.sequence < b.sequence) return -1
                                                    //if(!a.sequence || !b.sequence) return -1
                                                    //if((a.sequence && b.sequence) ? a.sequence > b.sequence : false) return 1;
                                                    //if((a.sequence && b.sequence) ? a.sequence < b.sequence : false) return -1;
                                                    //if(a.sequence === 0 || b.sequence === 0) return -1
                                                    return 0;
                                                }
                                            }).map((TeamPlayer) => (
                                            <TableRow key={TeamPlayer.id}>
                                                <TableCell sx={sxProps}>
                                                {/* {(TeamPlayer.position === 1) && (
                                                    <Box>TOR</Box>
                                                )}
                                                {(TeamPlayer.position === 2) && (
                                                    <Box>ABW</Box>
                                                )}
                                                {(TeamPlayer.position === 3) && (
                                                    <Box>MIT</Box>
                                                )}
                                                {(TeamPlayer.position === 4) && (
                                                    <Box>ANG</Box>
                                                )} */}
                                                <ShirtComponent 
                                                    teamPlayerPosition={TeamPlayer.position} 
                                                    number={TeamPlayer.number}                                                
                                                    showPosition={true} />
                                                </TableCell>
                                                <TableCell sx={sxProps}>{TeamPlayer.sequence}</TableCell>
                                                <TableCell onClick={() => handleOpenTeamPlayerDetails(TeamPlayer)} sx={{backgroundColor: '#DF9595', cursor: 'pointer'}}>{TeamPlayer.name} {TeamPlayer.number ? '(' + TeamPlayer.number + ')' : '' }</TableCell>
                                                <TableCell sx={sxProps}>
                                                    <Box title={teamPlayersModel.teams.find(s => s.id === TeamPlayer.teamId)?.name} className='teamlogo-fit' sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + teamPlayersModel.teams.find(s => s.id === TeamPlayer.teamId)?.imageLogoPath + ')'}} />
                                                </TableCell>
                                                <TableCell sx={sxProps} align='right'>{TeamPlayer.price}</TableCell>
                                                <TableCell>{teamPlayersModel.userSaisonAllocs.find(s => s.id === TeamPlayer.userSaisonAllocId)?.playerName}</TableCell>
                                                <TableCell onClick={() => handleOpenTeamPlayerDetails(teamPlayersModel.teamPlayers.find(s => s.id === TeamPlayer.newTeamPlayerId))}  sx={{backgroundColor: 'darkseagreen', cursor: 'pointer'}}>
                                                    {TeamPlayer.newTeamPlayerId && (
                                                        <Box>{teamPlayersModel.teamPlayers.find(s => s.id === TeamPlayer.newTeamPlayerId)?.name}</Box>
                                                    )}    
                                                </TableCell>
                                                <TableCell sx={sxProps}>
                                                    {TeamPlayer.newTeamPlayerId && (
                                                        <Box className='teamlogo-fit' sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + teamPlayersModel.teams.find(s => s.id === teamPlayersModel.teamPlayers.find(s => s.id === TeamPlayer.newTeamPlayerId)?.teamId)?.imageLogoPath + ')'}} />
                                                    )}
                                                </TableCell>
                                                <TableCell sx={sxProps}>
                                                    {TeamPlayer.newTeamPlayerFrom && (
                                                        <Box>{moment(TeamPlayer.newTeamPlayerFrom).format('DD.MM.YYYY')} {moment(TeamPlayer.newTeamPlayerFrom).format('HH:mm')}</Box>
                                                    )}   
                                                </TableCell>
                                                <TableCell sx={sxProps}>{TeamPlayer.info}</TableCell>
                                                {teamPlayersModel.userSaisonAlloc.allocRole === AllocRole.owner && teamPlayersModel.saison.state !== SaisonState.Finished && (
                                                    <TableCell align='center'>
                                                        <Edit sx={{cursor: 'pointer'}} onClick={() => handleOpenEditDialog(TeamPlayer)} />
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                        <Grid container spacing={2}>
                        {teamPlayersModel?.userSaisonAllocs.map((UserSaisonAlloc) => (
                            <Grid item key={UserSaisonAlloc.id} xs={12} xl={6}>
                                <Card key={UserSaisonAlloc.id}>
                                    <CardHeader title={UserSaisonAlloc.playerName.toUpperCase()}></CardHeader>
                                    <CardContent>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell>NAME</TableCell>
                                                        <TableCell sx={sxProps} align='center'>VEREIN</TableCell>                                                   
                                                        <TableCell sx={sxProps} align='right'>PREIS</TableCell>
                                                        <TableCell sx={sxProps} align='right'>PREIS (KICKER/MIO)</TableCell>
                                                        {teamPlayersModel.userSaisonAlloc.id === UserSaisonAlloc.id && (
                                                            <TableCell align='center'></TableCell>
                                                        )}
                                                        {teamPlayersModel.userSaisonAlloc.allocRole === AllocRole.owner && teamPlayersModel.saison.state !== SaisonState.Finished && (
                                                            <TableCell align='center'></TableCell>
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {teamPlayersModel.teamPlayers.sort((a,b) => a.position - b.position).filter(s => s.userSaisonAllocId === UserSaisonAlloc.id).map((TeamPlayer, index) => (
                                                        <TableRow 
                                                            key={TeamPlayer.id}
                                                            >
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell padding={'none'}>
                                                                <ShirtComponent teamPlayerPosition={TeamPlayer.position} number={TeamPlayer.number} showPosition={true} />
                                                                {/* <PositionIcon position={TeamPlayer.position}/> */}
                                                            </TableCell>
                                                            <TableCell onClick={() => handleOpenTeamPlayerDetails(TeamPlayer)} 
                                                                sx={{
                                                                    textDecoration: !TeamPlayer.active ? 'line-through' : '', 
                                                                    cursor: 'pointer', 
                                                                    whiteSpace: 'nowrap',

                                                                    }}>
                                                                    {TeamPlayer.name}                                                            
                                                            </TableCell>
                                                            <TableCell sx={sxProps}>
                                                                <Box title={teamPlayersModel.teams.find(s => s.id === TeamPlayer.teamId)?.name} className='teamlogo-fit' sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + teamPlayersModel.teams.find(s => s.id === TeamPlayer.teamId)?.imageLogoPath + ')'}} />
                                                            </TableCell>
                                                        
                                                            <TableCell sx={sxProps} align='right'>{TeamPlayer.price}</TableCell>
                                                            <TableCell sx={sxProps} align='right'>{TeamPlayer.kickerPrice}</TableCell>
                                                            {teamPlayersModel.userSaisonAlloc.id === UserSaisonAlloc.id && (
                                                                <TableCell align='center' >
                                                                    {TeamPlayer.note && (
                                                                        <ChatBubble sx={{cursor: 'pointer'}} fontSize='small' onClick={() => handleOpenCommentDialog(TeamPlayer)} />
                                                                    )}
                                                                    {!TeamPlayer.note && (
                                                                        <ChatBubbleOutline sx={{cursor: 'pointer'}} fontSize='small' onClick={() => handleOpenCommentDialog(TeamPlayer)} />
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                            {teamPlayersModel.userSaisonAlloc.allocRole === AllocRole.owner && teamPlayersModel.saison.state !== SaisonState.Finished && (
                                                                <TableCell align='center'>
                                                                    <Edit sx={{cursor: 'pointer'}} fontSize='small' onClick={() => handleOpenEditDialog(TeamPlayer)} />
                                                                </TableCell>
                                                            )}
                                                        </TableRow>                                                  
                                                    ))}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
                                                        <TableCell colSpan={9} sx={{paddingLeft: 0}}>
                                                            GESAMT: {teamPlayersModel.teamPlayers.filter(s => s.userSaisonAllocId === UserSaisonAlloc.id && s.active).reduce((a, b) => a + b.price, 0)} |
                                                            KICKER (MIO): {teamPlayersModel.teamPlayers.filter(s => s.userSaisonAllocId === UserSaisonAlloc.id && s.active).reduce((a, b) => a + b.kickerPrice, 0).toFixed(1)}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>    
                                        </TableContainer>             
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                        </Grid>
                    <LineUpItemCommentDialogComponent open={openLineUpItemCommentDialog} onClose={handleCloseCommentDialog} selectedTeamPlayer={selectedTeamPlayer}  />
                    <TeamPlayerDialogComponent 
                        teamPlayer={selectedTeamPlayer} 
                        teams={teamPlayersModel.teams} 
                        userSaisonAllocs={teamPlayersModel.userSaisonAllocs}
                        teamPlayers={teamPlayersModel.teamPlayers.filter(s => s.userSaisonAllocId === selectedTeamPlayer?.userSaisonAllocId && s.active === true && s.id !== selectedTeamPlayer.id)}
                        open={openEditDialog} 
                        onClose={handleEditDialogClose}/>  
                    {selectedTeamPlayer && (
                        <TeamPlayerStatisticsDialogComponent 
                            open={openDetailsDialog}
                            onClose={handleDetailsDialogClose}
                            teamPlayerId={selectedTeamPlayer?.id}
                            />  
                    )}
                   
{/* 
                    {teamPlayersModel.userSaisonAlloc.allocRole === AllocRole.owner && teamPlayersModel.saison.state !== SaisonState.Finished && (
                        <Box>
                            <Box>
                                <Fab color="primary" aria-label="add" size="small" onClick={handleCreateNewTeamPlayer} sx={{position: 'fixed', bottom: {xs: '66px',  md: '16px'}, right: '16px'}}>
                                    <Add />
                                </Fab>
                            </Box>
                        </Box>
                    )}                 */}
                    </div>  
                    {/* <ScrollTop >
                        <Fab size="small" aria-label="scroll back to top" sx={{backgroundColor:'#2e7d32', color:'#FFF', ":hover": {backgroundColor:'#2e7d32'}}}>
                            <KeyboardArrowUp />
                        </Fab>
                    </ScrollTop> */}          
                </Fade>
            )}
            {!teamPlayersModel && (
                <Loader delay={1500}>Spieler werden geladen...</Loader>
            )}
        </div>
    )
}
export default TeamPlayersPage