import styled from "@emotion/styled";
import { Team, TeamPlayerSuggest, UserSaisonAlloc } from "../../types/Saison";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import HttpService from "../../services/HttpService";
import DialogProgressComponent from "../common/DialogProgressComponent";

function TeamPlayerSuggestTransferDialogComponent(props: {
    open:boolean
    onClose: () => void
    teamPlayerSuggest?:TeamPlayerSuggest
    userSaisonAllocs:UserSaisonAlloc[]
    team?:Team
}) {

    const { open, onClose } = props
    const [ loading, setLoading ] = React.useState(false);
    const [ selectedUserSaisonAlloc, setSelectedUserSaisonAlloc ] = React.useState<UserSaisonAlloc>();
    const [ price, setPrice ] = React.useState(0.0);

    const handleCancel = () => {
        onClose();
        reset();
    }

    const handleOk = () => {
        setLoading(true)

        HttpService.post("/SaisonAPI/TeamPlayer",  {
            name: props.teamPlayerSuggest?.name,
            teamId: props.teamPlayerSuggest?.teamId,
            position: props.teamPlayerSuggest?.position,
            kickerPrice: props.teamPlayerSuggest?.kickerPrice,
            number: props.teamPlayerSuggest?.number,
            price: price,
            active: true,
            userSaisonAllocId: selectedUserSaisonAlloc?.id
        }, {params : {
            teamPlayerSuggestId : props.teamPlayerSuggest?.id
        }}).then(res => {
        if(res.status === 200) {
            handleCancel();      
            setLoading(false);
        } 
        })
    }

    const reset = () => {
        setSelectedUserSaisonAlloc(undefined)
        setPrice(0.0)
        setLoading(false)
    }


    return (
        <TeamPlayerSuggestTransferDialog open={open}>
            <DialogTitle display={'flex'} flexDirection={'row'}>
                {props.teamPlayerSuggest && (
                    <Box className='teamlogo-fit' minWidth={40} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.team?.imageLogoPath + ')'}} />
                )}
                <Box marginLeft={2}>{props.teamPlayerSuggest?.name}</Box>
            </DialogTitle>
            {loading && (
                <DialogProgressComponent delay={1000} />
            )}
             <DialogContent dividers>
                <Box component="form">
                    <FormControl sx={{marginTop: 3}} fullWidth>
                        <InputLabel>MANAGER</InputLabel>
                        <Select fullWidth={true} defaultValue={selectedUserSaisonAlloc} size="small" label="MANAGER" onChange={e => setSelectedUserSaisonAlloc(props.userSaisonAllocs.find(s => s.id === e.target.value))}>
                            {props.userSaisonAllocs?.sort((a,b) => a.playerName.localeCompare(b.playerName)).map((UserSaisonAlloc) => (
                                <MenuItem key={UserSaisonAlloc.id} value={UserSaisonAlloc.id}>{UserSaisonAlloc.playerName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField 
                        sx={{marginTop: 3}} 
                        type="number" 
                        size="small" 
                        fullWidth 
                        id="outlined-basic" 
                        label="PREIS" 
                        variant="outlined" 
                        defaultValue={price} onChange={e => setPrice(Number(e.target.value))} 
                        onFocus={e => e.target.select()}
                        />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleOk} disabled={loading || selectedUserSaisonAlloc === undefined}>ÜBERNEHMEN</Button>
            </DialogActions>
        </TeamPlayerSuggestTransferDialog>
    )
}

export default TeamPlayerSuggestTransferDialogComponent

const TeamPlayerSuggestTransferDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))