import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/de';
import { MatchDayMatch, MatchDayTeam } from '../../types/MatchDay';
import { Team } from '../../types/Saison';
import MatchDayMatchComponent, { MatchDayMatchVariant } from './MatchDayMatchComponent';

export default function MatchDayMatches(props: {
    matchDayMatches: MatchDayMatch[], 
    matchDayTeams: MatchDayTeam[], 
    teams:Team[],
    variant?:MatchDayMatchVariant
    serverDateTime:string
}) {

    var currentMatchDate = "";
    var dates:string[] = []

    props.matchDayMatches.sort(function(a,b) {
        if(a.matchDate < b.matchDate) {
            return -1
        } else {
            return 1
        }
    })

    props.matchDayMatches.forEach(function(matchDayMatch) {
        if(currentMatchDate.length === 0 || moment(currentMatchDate).format("DD.MM.YYYY") !== moment(matchDayMatch.matchDate).format("DD.MM.YYYY")) {
            currentMatchDate = matchDayMatch.matchDate
            dates.push(currentMatchDate)
        }
    })

    moment.updateLocale('de', null)

    return(
        <Grid container>
            {dates.map((date) => (
                <Grid key={date} item xs={12}>
                    {props.variant !== MatchDayMatchVariant.small && (
                    <Grid item xs={12} textAlign="center" /*sx={{fontWeight: 'bold'}}*/ marginBottom="7px" fontSize='small'>
                        {moment(date).format('dddd, DD.MM.YYYY').toUpperCase()}
                    </Grid>
                    )}

                    {props.matchDayMatches.filter(m => moment(m.matchDate).format("DD.MM.YYYY") === moment(date).format("DD.MM.YYYY")).map((matchDayMatch) => (
                        matchDayMatch.matchDayTeamsId1 !== null && matchDayMatch.matchDayTeamsId2 !== null && (
                            <MatchDayMatchComponent marginBottom="6px"
                                key={matchDayMatch.id} 
                                matchDayMatch={matchDayMatch}
                                matchDayTeam1={props.matchDayTeams.find(m => m.id === matchDayMatch.matchDayTeamsId1)}
                                matchDayTeam2={props.matchDayTeams.find(m => m.id === matchDayMatch.matchDayTeamsId2)}
                                serverDateTime={props.serverDateTime}
                                team1={props.teams.find(m => m.id === props.matchDayTeams.find(m => m.id === matchDayMatch.matchDayTeamsId1)?.teamId)}
                                team2={props.teams.find(m => m.id === props.matchDayTeams.find(m => m.id === matchDayMatch.matchDayTeamsId2)?.teamId)}
                                variant={props.variant}
                            />
                        )
                    ))}
                </Grid>
            ))}
        </Grid> 
    )
}