import { Toolbar, Divider, List, ListItemButton, ListItemIcon, ListItemText, Box, CssBaseline, AppBar, IconButton, Menu, MenuItem, Drawer, Button, Typography, Collapse, BottomNavigation, BottomNavigationAction } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useContext } from 'react';
import HttpService from '../../services/HttpService';
import { AccountCircle, EmojiEventsOutlined, EventOutlined, GroupsOutlined, HomeOutlined, InfoOutlined, List as ListIcon, Person2Outlined, RedoOutlined, SettingsOutlined, TodayOutlined } from '@mui/icons-material';
import {Route, Routes, useNavigate, NavLink, useLocation} from 'react-router-dom';
import HomePage from '../../routes/HomePage';
import ErrorPage from '../../routes/ErrorPage';
import { Account } from '../../types/Common';
import SaisonPage from '../../routes/SaisonPage';
import TeamPage from '../../routes/TeamPage';
import MatchDayPage from '../../routes/MatchDayPage';
import { AppContext } from '../../App';
import MatchPage from '../../routes/MatchPage';
import MatchDaysPage from '../../routes/MatchDaysPage';
import TeamPlayersPage from '../../routes/TeamPlayersPage';
import AdministrationPage from '../../routes/AdministrationPage';
import PlayersPage from '../../routes/PlayersPage';
import { AllocRole } from '../../types/Saison';
import TeamsPage from '../../routes/TeamsPage';
import SaisonsPage from '../../routes/SaisonsPage';
import InfoPage from '../../routes/InfoPage';
import HallOfFame from '../../routes/HallOfFame';
import ImpressumPage from '../../routes/ImpressumPage';
import PrivacyPage from '../../routes/PrivacyPage';
import TeamPlayerSuggestsPage from '../../routes/TeamPlayerSuggestsPage';
import AccountDialogComponent from './AccountDialogComponent';
import ImageComponent from './ImageComponent';
import ChangeSizeDialogComponent from './ChangeSizeDialogComponent';
import { ReactComponent as Logo } from '../../res/images/Logo.svg'

interface Props {
    window?: () => Window;
    onLogout: Function;
    onLogin: Function;
    account: Account | undefined;
}

function ResponsiveDrawer(props: Props) {

    const { window, onLogout, onLogin, account } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openAccountDialog, setOpenAccountDialog] = React.useState(false)
    const [openChangeSizeDialog, setOpenChangeSizeDialog] = React.useState(false)

    const navigate = useNavigate();
    const location = useLocation();

    const ctx = useContext(AppContext);

    const routes = (
        <Routes>
            <Route path="*" element={<ErrorPage />} />
            <Route path="/" element={<HomePage />} errorElement={<ErrorPage />}/>
            <Route path="/saison/:id/:source?" element={<SaisonPage />}  />
            <Route path="/matchday/:id" element={<MatchDayPage />} />
            <Route path="/match/:id" element={<MatchPage />} />
            <Route path="/matchdays/:id" element={<MatchDaysPage />} />
            <Route path="/teamplayers/:id" element={<TeamPlayersPage />} />
            <Route path="/administration/:id" element={<AdministrationPage />} />
            <Route path="/players" element={<PlayersPage />} />
            <Route path="/teams" element={<TeamsPage/>} />
            <Route path="/saisons" element={<SaisonsPage />} />
            <Route path="/info/:id" element={<InfoPage />} />
            <Route path="/halloffame" element={<HallOfFame />} />
            <Route path="/impressum" element={<ImpressumPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/team/:id" element={<TeamPage />} />
            <Route path="/teamplayersuggests/:id" element={<TeamPlayerSuggestsPage />} />
            <Route path="/team/:id/:returnUrl" element={<TeamPage />} />
        </Routes>
    )




    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
 
    const handleLogout = () => {
        HttpService.get("AccountAPI/Logoff").then(res => {
            localStorage.removeItem('token')
        })
        setAnchorEl(null); 
        onLogout();
        navigate("/");
    };

    const handleEditAccount = () => {
        setAnchorEl(null); 
        setOpenAccountDialog(true);
    }

    const handleChangeSize = () => {
        setAnchorEl(null); 
        setOpenChangeSizeDialog(true)
    }

    const handleSaveAccount = () => {
        setOpenAccountDialog(false);
    }

    const handleLogin = () => {
        onLogin();
    }

    const container = window !== undefined ? () => window().document.body : undefined;
    
      const drawer = (  
        <Box sx={{overflowX: 'hidden'}}>
          <Toolbar onClick={() => { navigate("/") }} sx={{ cursor: 'pointer'}}>
            <Box>
                {/* <Typography fontWeight='bold' position='absolute' sx={{bottom:{xs: '20px', md: '15px'}, fontSize:{xs:'x-large', md:'xx-large'}}}>
                    MANAGER
                </Typography>
                <Typography position='absolute' letterSpacing='2.4em' 
                    sx={
                        {
                         bottom:{xs: '13px', md: '8px'},
                         fontSize:{xs: 'x-small', md: 'small'},
                         letterSpacing:{xs: '1.48rem', md: '2rem'},
                         marginLeft: '1px'
                        }}>
                    SPIEL
                </Typography> */}
                <Logo opacity={0.8} width='11em' height='4em' />
            </Box>
          </Toolbar>                                                                                                                                                                                        
          <Divider />
            {ctx?.saison && (
            <Box>
                <List>
                    <Collapse in={ctx.saison.id !== undefined} timeout="auto" unmountOnExit>
                        {/* <Typography variant='subtitle2' sx={{marginLeft: '24px', marginRight: '24px', marginBottom: '10px'}}>{ctx.saison.name?.toUpperCase()}</Typography> */}
                        <List component="div" disablePadding>
                            <NavLink
                                to={`saison/${ctx.saison.id}`}
                                className="sidebar-item"
                                >
                                <ListItemButton>
                                    {/* <ListItemIcon>
                                        <HomeOutlined />
                                    </ListItemIcon> */}
                                    <ListItemText primaryTypographyProps={{fontSize: '0.75rem'}}  primary={ctx.saison.name?.toUpperCase()}/>
                                </ListItemButton>
                            </NavLink>
                            <NavLink  
                                to={`matchdays/${ctx.saison.id}`}                              
                                className={({ isActive }) =>
                                    isActive ? "sidebar-item-active" : "sidebar-item"
                                }                                                       
                                >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <EventOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="SPIELTAGE" />
                                </ListItemButton>
                            </NavLink>
                            {ctx.currentMatchDay && ctx.currentMatchDay.number > 0 && (
                                <Collapse in={true} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <NavLink  
                                        to={`matchday/${ctx.currentMatchDay.id}`}
                                        className={({ isActive }) =>
                                            isActive ? "sidebar-item-active" : "sidebar-item"
                                        }                                  
                                        >
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <RedoOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary={ctx.currentMatchDay.number + ". SPIELTAG"}/>
                                            </ListItemButton>
                                        </NavLink>
                                    </List>
                                </Collapse>
                            )}

                            <NavLink  
                                to={`teamplayers/${ctx.saison.id}`}
                                className={({ isActive }) =>
                                    isActive ? "sidebar-item-active" : "sidebar-item"
                                }                                  
                                >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <GroupsOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="KADER" />
                                </ListItemButton>
                            </NavLink>
                            <NavLink  
                                to={`info/${ctx.saison.id}`}
                                className={({ isActive }) =>
                                    isActive ? "sidebar-item-active" : "sidebar-item"
                                }                                  
                                >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <InfoOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="INFOS" />
                                </ListItemButton>
                            </NavLink>
                            <NavLink
                                to={`teamplayersuggests/${ctx.saison.id}`}
                                className={({ isActive }) =>
                                    isActive ? "sidebar-item-active" : "sidebar-item"
                                }                                  
                                >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <Person2Outlined />
                                    </ListItemIcon>
                                    <ListItemText primary="SPIELERPOOL" />
                                </ListItemButton>
                            </NavLink>
                            {ctx.userSaisonAlloc?.allocRole === AllocRole.owner && (
                                <NavLink  
                                    to={`administration/${ctx.saison.id}`}
                                    className={({ isActive }) =>
                                        isActive ? "sidebar-item-active" : "sidebar-item"
                                    }                                  
                                    >
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <SettingsOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary="ADMINISTRATION" />
                                    </ListItemButton>
                                </NavLink>
                            )}

                        </List>
                    </Collapse>
                </List>
            </Box>
            )}
            <Box>
            <Divider />
                {account && (
                <List>
                    <NavLink  
                        to={`saisons`}
                        className={({ isActive }) =>
                            isActive ? "sidebar-item-active" : "sidebar-item"
                        }                                  
                        >
                        <ListItemButton>
                            <ListItemIcon>
                                <ListIcon />
                            </ListItemIcon>
                            <ListItemText primary="SAISONS" />
                        </ListItemButton>
                    </NavLink>
                    <NavLink
                        to={`halloffame`}
                        className={({ isActive }) =>
                            isActive ? "sidebar-item-active" : "sidebar-item"
                        }                                  
                        >
                        <ListItemButton>
                            <ListItemIcon>
                                <EmojiEventsOutlined />
                            </ListItemIcon>
                            <ListItemText primary="HALL OF FAME" />
                        </ListItemButton>
                    </NavLink>
                </List>
                )} 
            </Box>
            {props.account?.roles.find(s => s === "ServerAdmin") && (
            <Box>
                <Divider />
                <List>
                    <NavLink  
                        to={`teams`}
                        className={({ isActive }) =>
                            isActive ? "sidebar-item-active" : "sidebar-item"
                        }                                  
                        >
                        <ListItemButton>
                            <ListItemIcon>
                                <GroupsOutlined />
                            </ListItemIcon>
                            <ListItemText primary="VEREINE" />
                        </ListItemButton>
                    </NavLink>
                    {/* <NavLink  
                        to={`players`}
                        className={({ isActive }) =>
                            isActive ? "sidebar-item-active" : "sidebar-item"
                        }                                  
                        >
                        <ListItemButton>
                            <ListItemIcon>
                                <Groups />
                            </ListItemIcon>
                            <ListItemText primary="SPIELER" />
                        </ListItemButton>
                    </NavLink> */}
                </List>
            </Box>
            )}
            <Divider />
                <Box sx={{marginLeft: '15px', marginTop: '25px'}}>
                    <NavLink
                            to={'impressum'}
                        >
                            <Typography sx={{marginRight: '8px', float: 'left', cursor: 'pointer', textDecoration: 'none', color:'#CCCCCC'}} fontSize={'small'}>Impressum</Typography>
                    </NavLink>
                    <Box></Box>
                    <NavLink
                            to={'privacy'}
                        >
                            <Typography sx={{float: 'left', cursor: 'pointer', textDecoration: 'none', color:'#CCCCCC'}} fontSize={'small'}>Datenschutz</Typography>
                    </NavLink>
                </Box>
        </Box>
      );
      return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                    width: { md: `calc(100% - 240px)` },
                    ml: { md: `240px` },
                    }}
                >
                    <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon sx={{fill: 'rgb(204,204,204)'}}/>
                    </IconButton>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'inherit' } }}></Box>
                    <Box sx={{ flexGrow: 1, paddingTop: '0.4em', display: { md: 'none' }, cursor: 'pointer' }} onClick={() => { navigate("/") }}>
                        <Logo opacity={0.8} width='9em' height='2em' />
                    </Box>
                    {/* <Box sx={{ flexGrow: 1, display: { md: 'none' }, cursor: 'pointer' }} onClick={() => { navigate("/") }}>
                        <Typography fontWeight='bold' fontSize='x-large' position='absolute' sx={{bottom: '15px', color: '#CCCCCC'}}>
                            MANAGER
                        </Typography>
                        <Typography fontSize='x-small' position='absolute' letterSpacing='1.48rem' sx={{marginLeft: '1px', bottom: '8px', color: '#CCCCCC'}}>
                            SPIEL
                        </Typography>
                        
                    </Box> */}
                    {/* {ctx?.userSaisonAlloc?.allocRole === AllocRole.owner && (
                        <Button color="inherit">ADMIN</Button>
                    )} */}
                    {account && (
                        <Typography sx={{display: {xs: 'none', sm: 'block'}}}>{account.userName}</Typography>
                    )}
                    {!account && (
                        <Button color="inherit" onClick={handleLogin}>Login</Button>
                    )}
                    {account && (
                        <div>
                            {account.imageFileName && (
                                <IconButton
                                    //size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                    
                                >
                                 <ImageComponent 
                                    className= {`avatar`}
                                    width={'36px'}
                                    fileName={'/images/upload/avatar/' + account.imageFileName} 
                                    base64Thumb={account.avatarBase64Thumb}/>

                                {/* <Box className={"avatar"} sx={{minHeight: '36px', minWidth: '36px', backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/avatar/' + account.imageFileName + ')'}}></Box> */}

                                </IconButton>
                            )}
                            {!account.imageFileName && (
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle sx={{fontSize: '36px', fill: 'rgb(204,204,204)'}} />
                                </IconButton>
                            )}

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleEditAccount}>Avatar</MenuItem>
                                <MenuItem onClick={handleChangeSize}>Einstellungen</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                {/* <MenuItem disabled={true}>
                                    <Box>
                                        <Typography fontSize={'xx-small'}>{process.env.REACT_APP_VERSION}</Typography>
                                    </Box>
                                </MenuItem> */}
                            </Menu>
                        </div>
                    )}
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { md: 240 }, flexShrink: { md: 0 } }}
                    aria-label="mailbox folders"
                >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Drawer
                        className='sidebar'
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        onClick={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                            //onBackdropClick: handleDrawerToggle
                        }}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, backgroundColor: '#252526', color: '#CCCCCC' },
                        }}
                        >
                        {drawer}
                    </Drawer>
                    <Drawer
                        className='sidebar'
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, backgroundColor: '#252526', color: '#CCCCCC' },
                        }}
                        open
                        >
                        {drawer}
                    </Drawer>   
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 1, width: { md: `calc(100% - ${240}px)` }, backgroundColor: '#c5d1c3', minHeight: "100vh", marginBottom: (ctx?.saison ? {xs: '56px', md: '0px'} : '0px'), marginTop: {xs: '56px', md: '64px'}}}
                >
                    {/* <Toolbar /> */}
                    {routes}
                </Box>
                {ctx?.saison && (
                    <BottomNavigation component={'footer'} sx={
                        {
                            display: {md: 'none'}, 
                            position: 'fixed', 
                            zIndex: '1100', 
                            bottom: 0, 
                            right: 0, 
                            width: { xs: '100%', md: `calc(100% - ${240}px)` }, 
                            backgroundColor: '#252526', 
                            color: '#FFFFFF', 
                            boxShadow: '0px -2px 10px 0px rgba(0,0,0,0.2);',
                            "& .MuiBottomNavigationAction-label": {
                                fontSize: '1rem',
                            },
                            
                            "& .MuiBottomNavigationAction-label.Mui-selected": {
                                fontSize: '1.1rem',
                            },

                            "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
                                color: "#FFFFFF",
                                whiteSpace: 'nowrap'
                            }
                        ,"& .Mui-selected, .Mui-selected > svg": {
                            color: "#2e7d32"
                        }
                        }
                    }
                        showLabels
                        value={
                            (
                                location.pathname.search('saison/') > 0 ? 0 : 
                                location.pathname.search('matchday/' + ctx.currentMatchDay?.id) > 0 ? 1 :
                                location.pathname.search('matchdays/') > 0 ? 2 :
                                location.pathname.search('teamplayers/') > 0 ? 3 :
                                -1
                            )
                        }
                        >
                        <BottomNavigationAction label={'HOME'} icon={<HomeOutlined />} onClick={() => navigate('/saison/' + ctx?.saison?.id)} />
                        {ctx.currentMatchDay && (
                            <BottomNavigationAction label={ctx.currentMatchDay.number + ". SPIELTAG"} icon={<TodayOutlined />} onClick={() => navigate('/matchday/' + ctx?.currentMatchDay?.id)} />
                        )}
                        {!ctx.currentMatchDay && (
                            <BottomNavigationAction label={'SPIELTAGE'} icon={<EventOutlined />} onClick={() => navigate('/matchdays/' + ctx?.saison?.id)} />
                        )}
                        <BottomNavigationAction label={'KADER'} icon={<GroupsOutlined />} onClick={() => navigate('/teamplayers/' + ctx?.saison?.id)} />
                    </BottomNavigation>
                )}
                {account && (
                    <Box>
                        <AccountDialogComponent 
                            open={openAccountDialog}
                            account={account}
                            onSave={handleSaveAccount}
                            onClose={() => setOpenAccountDialog(false)}
                        />
                        <ChangeSizeDialogComponent
                            account={account}
                            open={openChangeSizeDialog}
                            onClose={() => setOpenChangeSizeDialog(false)}
                        />
                    </Box>
                )}
                
            </Box>
            
      );
}

export default ResponsiveDrawer;