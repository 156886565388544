import { Box, Fab } from "@mui/material";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid"
import React from "react";
import HttpService from "../services/HttpService";
import { Player } from "../types/Common";
import { AppContext } from "../App";
import { Add } from "@mui/icons-material";
import PlayerDialogComponent from "../components/common/PlayerDialogComponent";

const PlayersPage = () => {

    const [ players, setPlayers ] = React.useState<Array<Player>>(new Array<Player>())
    const [ selectedPlayer, setSelectedPlayer ] = React.useState();
    const [ openDialog, setOpenDialog ] = React.useState(false);
    const [ reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', },
        { field: 'name', headerName: 'NAME' },
        { field: 'birthday', headerName: 'GEB' },
        { field: 'imageLogoPath', headerName: 'BILD', renderCell: (params) => {
          console.log(params);
          return (
            <Box>
            {params.value && (
              <Box className='teamlogo-fit' height={45} minWidth={50} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/players/' + params.value + ')'}} />
            )}
            </Box>
          );}
        }
    ]

    const ctx = React.useContext(AppContext)

    React.useEffect(function() {
      HttpService.get('/CommonAPI/Players'
          ).then(res => {
              if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                  console.log(res.data);
              }   
              setPlayers(res.data)
          });
    },[reducerValue])

    const handleCreateNewPlayer = () => {
      setSelectedPlayer(undefined)
      setOpenDialog(true);
    }

    const handleCloseDialog = () => {
      setOpenDialog(false);
    }

    const handleSavePlayer = () => {
      setOpenDialog(false);
      forceUpdate();
    }

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
      setSelectedPlayer(params.row)
      setOpenDialog(true);
    };

    return (
    <div>
        <Box sx={{ width: '100%' }}>
            <DataGrid 
                onRowClick={handleRowClick}
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                  }
                }}
                // density="compact"
                autoHeight
                rows={players}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 20,
                    },
                  },
                }}
                pageSizeOptions={[20]}
                disableRowSelectionOnClick
            />  
            {ctx?.account?.roles.find(s => s === "ServerAdmin") && (
                <Box>
                    <Fab color="primary" aria-label="add" size="small" onClick={handleCreateNewPlayer} sx={{position: 'fixed', bottom: '16px', right: '16px'}}>
                        <Add />
                    </Fab>
                </Box>
            )}
            <PlayerDialogComponent open={openDialog} onClose={handleCloseDialog} onSave={handleSavePlayer} player={selectedPlayer}/>
        </Box>
    </div>
    )
}
export default PlayersPage