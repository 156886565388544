import { Error, Info, Warning } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";

export interface ConfirmationDialogRawProps {
    message?:string;
    title:string;
    type:AlertType;
    open: boolean;
    onOk: () => void;
  }

  export enum AlertType {
    ERROR,
    INFO,
    WARNING
  }

function AlertDialog(props: ConfirmationDialogRawProps) {
    const { onOk, open, ...other } = props;
    
    const handleOk = () => {
        onOk();
    }

    return (
        <AlertDia
            open={open}
            {...other}
        > 
            <DialogTitle>
                <Box sx={{float:'left', marginRight: '10px'}}>
                {props.type === AlertType.ERROR && (
                    <Error color='error' sx={{fontSize: '2rem'}} />
                )}
                {props.type === AlertType.WARNING && (
                    <Warning color='warning' sx={{fontSize: '2rem'}} />
                )}
                {props.type === AlertType.INFO && (
                    <Info color='info' sx={{fontSize: '2rem'}} />
                )}
                </Box>
                <Box>{props.title}</Box>
            </DialogTitle>
            <DialogContent dividers>
            <Box>{props.message}</Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleOk}>
                    Ok
                </Button>
            </DialogActions>
        </AlertDia>
    )
}
export default AlertDialog

const AlertDia = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(90% - 10px)',
        width: 'calc(90% - 10px)',
    }
}))