import { Avatar } from "@mui/material"
import { TeamPlayerPosition } from "../../types/Saison"

function PositionIcon(props: {position?:TeamPlayerPosition}) {

    var bgColor = "white";
    var value = "";

    switch(props.position) {
        case TeamPlayerPosition.goalkeeper: 
            bgColor = 'indianRed';
            value = 'T';
            break;
        case TeamPlayerPosition.defender: 
            bgColor = 'steelblue';
            value = 'A';
            break;
        case TeamPlayerPosition.midfielder: 
            bgColor = 'seagreen';
            value = 'M';
            break;
        case TeamPlayerPosition.striker: 
            bgColor = 'dimgray';
            value = 'S';
    }

    return (
        <Avatar sx={{ width: 24, height: 24, fontSize: '0.825rem', border: '1px solid black',  backgroundColor: bgColor}}>{value}</Avatar>
    )
}

export default PositionIcon