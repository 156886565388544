import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, MenuItem, Select, styled, TextField } from "@mui/material";
import React from "react";
import HttpService from "../../services/HttpService";
import { MatchDay, MatchDayMatch, MatchDayState, MatchDayTeam } from "../../types/MatchDay";
import { Team } from "../../types/Saison";
import moment from "moment";

export interface Props {
    open: boolean
    matchDay: MatchDay
    matchDayMatches: MatchDayMatch[]
    matchDayTeams: MatchDayTeam[]
    teams: Team[]
    onClose: (reload:boolean) => void
}

function MatchDayMatchesDialogComponent(props:Props) {

    const [reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [matchDayFinished, setMatchDayFinished] = React.useState(false);
    const [notifyManager, setNotifyManager] = React.useState(false);
    const [choosenDateTime, setChoosenDateTime] = React.useState(moment());

    React.useEffect(function() {
        setMatchDayFinished(props.matchDay.state === MatchDayState.FINISHED)
    }, [props.matchDay])

    const handleCancel = () => {
        props.onClose(true);
    }

    const handleOk = () => {

        HttpService.post('/MatchDayAPI/MatchDay', {
            MatchDay : props?.matchDay, 
            MatchDayMatches: props.matchDayMatches, 
            MatchDayTeams: props.matchDayTeams,
            NotifyManager: notifyManager
        }).then(res => {

            if(res.status === 200) {
                props.onClose(false);
            } else if(res.status === 400) {
                var errorMessage = res.headers["error"];
                if(errorMessage !== undefined) {
                    //setError(errorMessage)
                }   
            }
        }) 
    }

    props.matchDayMatches.map((MatchDayMatch) => {
        if(!MatchDayMatch.matchDayTeamsId1 && !MatchDayMatch.matchDayTeamsId2) {
            MatchDayMatch.matchDate = choosenDateTime.format("YYYY-MM-DDTHH:mm")
        }
    })

    const handleOnChangeMatchDate = (MatchDayMatch:MatchDayMatch, value:string) => {
        MatchDayMatch.matchDate = value;
        setChoosenDateTime(moment(value));
        forceUpdate();
    }

    const handleTeamChange = (value:string, matchDayMatch:MatchDayMatch, team:number) => {
        var matchDayTeam = props.matchDayTeams.find(s => s.teamId === value);
        if(matchDayTeam !== undefined) {
            if(team === 1) {
                matchDayMatch.matchDayTeamsId1 = matchDayTeam?.id
            }else {
                matchDayMatch.matchDayTeamsId2 = matchDayTeam?.id
            }
        }
        forceUpdate();
    }

    const handleMatchDayStateChange = (event:any, checked:boolean) => {
        setMatchDayFinished(checked);
        if(!checked) {
            setNotifyManager(false);
            props.matchDay.state = MatchDayState.OPENED
        } else {
            props.matchDay.state = MatchDayState.FINISHED
        }
    }

    const handleNotifyManagerChange = (event:any, checked:boolean) => {
        setNotifyManager(checked);
    }

    return (
        <MatchDayMatchesDialog fullWidth={true} open={props.open}>
            <DialogTitle>
                SPIELTAG {props.matchDay.number}
            </DialogTitle>
            <DialogContent dividers>
                {props.matchDayMatches.map((MatchDayMatch) => (
                    <Grid container key={MatchDayMatch.id} marginBottom='5px'>
                        <Grid item xs={8} sm={6} paddingRight={'5px'}>
                            <TextField fullWidth={true} size="small"
                                value={MatchDayMatch.matchDate}
                                onChange={e => handleOnChangeMatchDate(MatchDayMatch, e.target.value)}
                                type='datetime-local'                       
                            />
                        </Grid>
                        <Grid item xs={2} sm={3} paddingRight={'5px'}>
                            <Select size="small" onChange={e => handleTeamChange(e.target.value, MatchDayMatch, 1)} fullWidth={true} value={MatchDayMatch.matchDayTeamsId1 !== null? props.matchDayTeams.find(s => s.id === MatchDayMatch.matchDayTeamsId1)?.teamId:''}>
                                {props.teams.map((Team) => (
                                    <MenuItem key={Team.id} value={Team.id}>                                        
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Box className='teamlogo-fit' width={'20px'} height={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + Team?.imageLogoPath + ')'}}>&nbsp;
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} sx={{display: {xs: 'none', sm:'table-cell'}}}>
                                                {Team.shortName}
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={2} sm={3}>
                        <Select size="small" onChange={e => handleTeamChange(e.target.value, MatchDayMatch, 2)} fullWidth={true} value={MatchDayMatch.matchDayTeamsId2 !== null? props.matchDayTeams.find(s => s.id === MatchDayMatch.matchDayTeamsId2)?.teamId:''}>
                                {props.teams.map((Team) => (
                                    <MenuItem key={Team.id} value={Team.id}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Box className='teamlogo-fit' width={'20px'} height={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + Team?.imageLogoPath + ')'}}>&nbsp;
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} sx={{display: {xs: 'none', sm:'table-cell'}}}>
                                                {Team.shortName}
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                ))}
                <Box>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={matchDayFinished} onChange={handleMatchDayStateChange} />} label="ABGESCHLOSSEN" />
                        <FormControlLabel disabled={!matchDayFinished} control={<Checkbox checked={notifyManager} onChange={handleNotifyManagerChange} />} label="MANAGER BENACHRICHTIGEN" />
                    </FormGroup>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>ABBRECHEN</Button>
                <Button onClick={handleOk}>ÜBERNEHMEN</Button>
            </DialogActions>
        </MatchDayMatchesDialog>
    )
}

const MatchDayMatchesDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))

export default MatchDayMatchesDialogComponent