import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, styled, TextField } from "@mui/material";
import React from "react";
import HttpService from "../../services/HttpService";
import { Team, TeamPlayer, TeamPlayerPosition, UserSaisonAlloc } from "../../types/Saison";
import DialogProgressComponent from "../common/DialogProgressComponent";

function TeamPlayerDialogComponent(props: {
    open:boolean,
    onClose: () => void;
    teamPlayer?:TeamPlayer,
    teams?:Team[],
    teamPlayers?:TeamPlayer[],
    userSaisonAllocs?:UserSaisonAlloc[]
}) {

    const { onClose } = props
    const [ id, setId ] = React.useState("")
    const [ name, setName ] = React.useState("")
    const [ position, setPosition ] = React.useState(TeamPlayerPosition.goalkeeper)
    const [ number, setNumber ] = React.useState(0);
    const [ teamId, setTeamId] = React.useState("")
    const [ userSaisonAllocId, setUserSaisonAllocId ] = React.useState("")
    const [ price, setPrice ] = React.useState(0.0)
    const [ kickerPrice, setKickerPrice ] = React.useState(0.0)
    const [ active, setActive ] = React.useState(false)
    const [ newTeamPlayerId, setNewTeamPlayerId] = React.useState("")
    const [ info, setInfo] = React.useState("")
    const [ newTeamPlayerFrom, setNewTeamPlayerFrom ] = React.useState<string | undefined>();
    const [ sequence, setSequence ] = React.useState<number | undefined>();

    const [loading, setLoading] = React.useState(false);

    const [availableTeamPlayers, setAvailableTeamPlayers] = React.useState<TeamPlayer[]>();

    React.useEffect(() => {
        if(props.teamPlayer) {
            setId(props.teamPlayer?.id)
            setName(props.teamPlayer?.name)
            setPosition(props.teamPlayer.position)
            setTeamId(props.teamPlayer.teamId === null ? "" : props.teamPlayer.teamId)
            setUserSaisonAllocId(props.teamPlayer.userSaisonAllocId === null ? "" : props.teamPlayer.userSaisonAllocId)
            setPrice(props.teamPlayer.price)
            setKickerPrice(props.teamPlayer.kickerPrice)
            setNewTeamPlayerId(props.teamPlayer.newTeamPlayerId === null ? "" : props.teamPlayer.newTeamPlayerId)
            setActive(props.teamPlayer.active)
            setInfo(props.teamPlayer.info)
            setNumber(props.teamPlayer.number ? props.teamPlayer.number : 0)
            setSequence(props.teamPlayer.sequence)
            setNewTeamPlayerFrom(props.teamPlayer.newTeamPlayerFrom);
        } else {
            reset()
        }
        setAvailableTeamPlayers(props.teamPlayers)
    },[props.teamPlayer])
    
    const handleChangePosition = (teamPlayerPosition:TeamPlayerPosition | string) => {
        setPosition(teamPlayerPosition as TeamPlayerPosition)
    }

    const handleCancel = () => {
        onClose();
        reset();
    }

    const handleDelete = () => {
        HttpService.get("/SaisonAPI/DeleteTeamPlayer", {
            params: { id: props.teamPlayer?.id }
            }).then(res => {if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              console.log(res.data);
            }
            onClose();
            reset();
          });    
    }

    const handleOk = () => {
        setLoading(true)
        HttpService.post("/SaisonAPI/TeamPlayer", {
            id: id,
            name: name,
            teamId: teamId,
            userSaisonAllocId: userSaisonAllocId,
            active: active,
            position: position,
            number: number,
            price: price,
            kickerPrice: kickerPrice,
            info: info,
            newTeamPlayerId: newTeamPlayerId,
            sequence: sequence,
            newTeamPlayerFrom: newTeamPlayerFrom
          }).then(res => {
            if(res.status === 200) {
                handleCancel();      
                setLoading(false);
            } 
          })
    }

    const reset = () => {
        setId("")
        setName("")
        setPosition(TeamPlayerPosition.goalkeeper)
        setNumber(0)
        setTeamId("")
        setUserSaisonAllocId("")
        setPrice(0.0)
        setKickerPrice(0.0)
        setNewTeamPlayerId("")
        setActive(false)
        setInfo("")
        setSequence(undefined);
        setNewTeamPlayerFrom(undefined)

        setLoading(false)
    }

    return(
        <TeamPlayerDialog open={props.open}>
            <DialogTitle display={'flex'} flexDirection={'row'}>
                {props.teamPlayer && (
                    <Box className='teamlogo-fit' minWidth={40} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.teams?.find(s => s.id === props.teamPlayer?.teamId)?.imageLogoPath + ')'}} />
                )}
                <Box marginLeft={2}>{name.length === 0 ? "Neuer Spieler" : name}</Box>
            </DialogTitle>
            {loading && (
                <DialogProgressComponent delay={1000} />
            )}
            <DialogContent dividers>
                <Box component="form">
                    <TextField sx={{marginTop: 3}} required={true} size="small" fullWidth id="outlined-basic" label="NAME" variant="outlined" value={name} onChange={e => setName(e.target.value)} />
                    <FormControl sx={{marginTop: 3}} fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">POSITION</InputLabel>
                        <Select fullWidth={true} value={position} size="small" label="POSITION" onChange={e => handleChangePosition(e.target.value)}>
                            <MenuItem value={TeamPlayerPosition.goalkeeper}>TOR</MenuItem>
                            <MenuItem value={TeamPlayerPosition.defender}>ABWEHR</MenuItem>
                            <MenuItem value={TeamPlayerPosition.midfielder}>MITTELFELD</MenuItem>
                            <MenuItem value={TeamPlayerPosition.striker}>ANGRIFF</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{marginTop: 3}} fullWidth>
                        <InputLabel>VEREIN</InputLabel>
                        <Select required={true} fullWidth={true} value={teamId} size="small" label="POSITION" onChange={e => setTeamId(e.target.value)}>
                            {props.teams?.sort((a,b) => a.name.localeCompare(b.name)).map((Team) => (
                                <MenuItem key={Team.id} value={Team.id}>{Team.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField sx={{marginTop: 3}} type="number" size="small" fullWidth id="outlined-basic" label="NUMMER" variant="outlined" value={number} onChange={e => setNumber(Number(e.target.value))} />
                    {/* <FormControl sx={{marginTop: 3}} fullWidth>
                        <InputLabel>MANAGER</InputLabel>
                        <Select readOnly fullWidth={true} value={userSaisonAllocId} size="small" label="MANAGER" onChange={e => setUserSaisonAllocId(e.target.value)}>
                            {props.userSaisonAllocs?.sort((a,b) => a.playerName.localeCompare(b.playerName)).map((UserSaisonAlloc) => (
                                <MenuItem key={UserSaisonAlloc.id} value={UserSaisonAlloc.id}>{UserSaisonAlloc.playerName}</MenuItem>
                            ))}
                        </Select>
                        <TextField size="small" label="MANAGER" value={props.userSaisonAllocs?.find(s => s.id === userSaisonAllocId)?.playerName}></TextField>
                    </FormControl> */}
                    <TextField sx={{marginTop: 3}} type="number" size="small" fullWidth id="outlined-basic" label="PREIS" variant="outlined" value={price} onChange={e => setPrice(Number(e.target.value))} />
                    <TextField sx={{marginTop: 3}} type="number" size="small" fullWidth id="outlined-basic" label="PREIS (KICKER)" variant="outlined" value={kickerPrice} onChange={e => setKickerPrice(Number(e.target.value))} />
                    <FormControlLabel sx={{marginTop: 1}} control={<Checkbox checked={active} onChange={e => setActive(e.target.checked)}></Checkbox>} label="AKTIV" />
                    {!active && props.teamPlayer?.id && (
                        <Box>
                            <TextField sx={{marginTop: 1}} InputLabelProps={{shrink: true }} size="small" fullWidth id="outlined-basic" label="NEUER SPIELER AB" variant="outlined" value={newTeamPlayerFrom} onChange={e => setNewTeamPlayerFrom(e.target.value)} type='datetime-local' placeholder="" />
                            <TextField sx={{marginTop: 3}} InputLabelProps={{shrink: true }} size="small" fullWidth id="outlined-basic" label="REIHENFOLGE" variant="outlined" value={sequence} onChange={e => setSequence(Number(e.target.value))} type="number"  placeholder=""/>
                            <FormControl sx={{marginTop: 3}} fullWidth>
                                <InputLabel>NEUER SPIELER</InputLabel>
                                <Select displayEmpty fullWidth={true} value={newTeamPlayerId} size="small" label="NEUER SPIELER" onChange={e => setNewTeamPlayerId(e.target.value)}>
                                    <MenuItem value=""></MenuItem>
                                    {availableTeamPlayers?.sort((a,b) => a.name.localeCompare(b.name)).map((NewTeamPlayer) => (
                                        <MenuItem key={NewTeamPlayer.id} value={NewTeamPlayer.id}>{NewTeamPlayer.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <TextField sx={{marginTop: 3}} multiline minRows={2} size="small" fullWidth id="outlined-basic" label="INFO" variant="outlined" value={info == null ? '' : info} onChange={e => setInfo(e.target.value)} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete}>LÖSCHEN</Button>
                <Button onClick={handleCancel} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleOk} disabled={loading}>ÜBERNEHMEN</Button>
            </DialogActions>
        </TeamPlayerDialog>      
    )
}
export default TeamPlayerDialogComponent

const TeamPlayerDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))