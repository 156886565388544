import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select, styled, Typography } from "@mui/material";
import { ConfirmImportTeamPlayerSuggestsModel } from "../../types/Saison";
import React from "react";
import DialogProgressComponent from "../common/DialogProgressComponent";
import HttpService from "../../services/HttpService";
import { CloudUpload } from "@mui/icons-material";

const TeamPlayerSuggestConfirmImportDialogComponent = (props: {

    open:boolean
    onCancel():void
    onSave():void
    saisonId:string

}) => {

    const { open } = props
    const [ loading, setLoading ] = React.useState(false);
    const [ confirmImportTeamPlayerSuggestsModel, setConfirmImportTeamPlayerSuggestsModel ] = React.useState<ConfirmImportTeamPlayerSuggestsModel | undefined>()
    const [ filename, setFilename ] = React.useState();
    const [ reducerValue, forceUpdate ] = React.useReducer(x => x + 1, 0);

    const handleCancel = () => {
        setConfirmImportTeamPlayerSuggestsModel(undefined)
        setFilename(undefined)
        props.onCancel()
    }

    const handleSave = () => {
        handleConfirmImportTeamPlayerSuggests()
    }

    const handleConfirmImportTeamPlayerSuggests = () => {

        setLoading(true)

        HttpService.post('/SaisonApi/ConfirmImportTeamPlayerSuggests', 
            confirmImportTeamPlayerSuggestsModel   
        ).then(res => {

            setLoading(false)

            if(res.data)

                setConfirmImportTeamPlayerSuggestsModel(undefined)
                setFilename(undefined)

                props.onSave();
        })
    }

    const handleImportTeamPlayerSuggests = (event:any) => {

        setLoading(true)

        Array.from(event.target.files).forEach((File:any, index) => {
            var formData = new FormData();
            if(props.saisonId) {
                formData.append("SaisonId", props.saisonId)
            }
            formData.append("file", File)
            HttpService.post('/SaisonApi/ImportTeamPlayerSuggests',
                formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }}
            ).then(res => {
                setFilename(File.name)
                setConfirmImportTeamPlayerSuggestsModel(res.data)
                setLoading(false)
            })
        })
    }

    const handleTeamMappingChange = (event:any) => {
        console.log(event)

        var selectedItem = confirmImportTeamPlayerSuggestsModel?.teamMappings.find(s => s.kickerTeam === event.target.name)
        if(selectedItem)
            selectedItem.teamId = event.target.value

        forceUpdate()
    }

    return(
        <TeamPlayerSuggestConfirmImportDialog open={open}>
            <DialogTitle>IMPORT</DialogTitle>
            {loading && (
                <DialogProgressComponent delay={1000} />
            )}
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} textAlign={'center'} paddingBottom={1}>
                        {filename && (
                            <Button component="label" color="primary" variant="contained" fullWidth>
                                 <input
                                    type="file"
                                    accept=".csv"
                                    hidden
                                    onChange={handleImportTeamPlayerSuggests}
                                />

                                {filename}

                            </Button>
                        )}
                        {!filename && (
                            <Button component="label" color="primary" variant="contained" startIcon={<CloudUpload />} fullWidth> 
                                <input
                                    type="file"
                                    accept=".csv"
                                    hidden
                                    onChange={handleImportTeamPlayerSuggests}
                                />
                                Upload File
                            </Button>
                        )}
                    </Grid>
                    
                </Grid>
                {confirmImportTeamPlayerSuggestsModel && (
                    <Grid container>
                        <Grid item xs={4}>
                            <Box>Gesamt</Box>
                        </Grid>
                        <Grid item xs={8}>
                            <Box>{confirmImportTeamPlayerSuggestsModel?.count}</Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>Aktualisieren</Box>
                        </Grid>
                        <Grid item xs={8}>
                            <Box>{confirmImportTeamPlayerSuggestsModel?.countUpdate}</Box>
                        </Grid>
                    </Grid>
                )}
                {confirmImportTeamPlayerSuggestsModel?.teamMappings && (
                    <Grid container marginTop={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Teamzuordnung</Typography>
                        </Grid>
                        {confirmImportTeamPlayerSuggestsModel?.teamMappings.map(TeamMapping => (
                            <Grid key={TeamMapping.kickerTeam} item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>{TeamMapping.kickerTeam}</Grid>
                                    <Grid item xs={6}>
                                        <FormControl  sx={{ minWidth: 'calc(100%)', mb: 1, width: '100%' }} size="small">
                                            <Select value={TeamMapping.teamId ? TeamMapping.teamId : ""} onChange={handleTeamMappingChange} name={TeamMapping.kickerTeam}>
                                                {confirmImportTeamPlayerSuggestsModel.teams.filter(s => s.name.toLowerCase() !== 'joker').map(Team => (
                                                    <MenuItem key={Team.id} value={Team.id}>{Team.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                )}
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>ABBRECHEN</Button>
                <Button onClick={handleSave} disabled={!confirmImportTeamPlayerSuggestsModel || confirmImportTeamPlayerSuggestsModel?.teamMappings.filter(s => s.teamId === null).length > 0}>ÜBERNEHMEN</Button>
            </DialogActions>
        </TeamPlayerSuggestConfirmImportDialog>
    )
}

export default TeamPlayerSuggestConfirmImportDialogComponent

const TeamPlayerSuggestConfirmImportDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))