import React from "react"
import HttpService from "../services/HttpService";
import { Saison } from "../types/Saison";
import { Box, Card, CardContent, CardHeader, Fab, Fade, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LockOpen, Lock, Add } from "@mui/icons-material";
import Loader from "../components/common/Loader";
import ConformationDialog from "../components/common/ConfirmationDialog";
import { AppContext } from "../App";

const SaisonsPage = () => {

    const [ saisons, setSaisons ] = React.useState<Array<Saison>>()
    const [ showConfirmDialog, setShowConfirmDialog ] = React.useState(false);

    const navigate = useNavigate();
    const ctx = React.useContext(AppContext)

    React.useEffect(function() {

        window.scrollTo(0, 0);

        HttpService.get('/SaisonAPI/Saisons'
            ).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }   
                setSaisons(res.data)
            });
    },[])

    const handleSaisonClick = (SaisonId:string) => {
        navigate('/saison/' + SaisonId)
    }

    const handleCreateNewSaison = () => {
        HttpService.get('/SaisonAPI/CreateNewSaison'
            ).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }
                setShowConfirmDialog(false);
                navigate("/saison/" + res.data.id);
            });
    }

    return (
        <div>
            {saisons && (
                <Fade in>
                    <Box>
                        <Card>
                            <CardHeader title="SAISONS" />
                            <CardContent>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>NAME</TableCell>
                                                <TableCell>STATUS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {saisons.map((Saison) => (
                                                <TableRow 
                                                    key={Saison.id} 
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                                    onClick={e => handleSaisonClick(Saison.id)}
                                                    >
                                                    <TableCell>{Saison.name}</TableCell>
                                                    <TableCell>
                                                    {Saison.state === 0 && (
                                                        <LockOpen />
                                                    )}
                                                    {Saison.state > 0 && (
                                                        <Lock />
                                                    )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <ConformationDialog
                                        onCancel={() => setShowConfirmDialog(false)}
                                        onNo={() => setShowConfirmDialog(false)}
                                        open={showConfirmDialog}
                                        title="NEUE SAISON"
                                        onYes={handleCreateNewSaison}
                                    >
                                        Neue Saison erstellen?
                                    </ConformationDialog>
                                </TableContainer>
                            </CardContent>
                        </Card>
                        {ctx?.account?.roles.find(s => s === "ServerAdmin") && (
                            <Box>
                                <Fab color="primary" aria-label="add" size="small" onClick={() => setShowConfirmDialog(true)} sx={{position: 'fixed', bottom: {xs: '66px',  md: '16px'}, right: '16px'}}>
                                    <Add />
                                </Fab>
                            </Box>
                        )}
                    </Box>
                </Fade>
            )}
            {!saisons && (
                <Loader delay={1500}>Saisons werden geladen...</Loader>
            )}
        </div>
    )
}

export default SaisonsPage