import React from "react";
import HttpService from "../../services/HttpService";
import styled from "@emotion/styled";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import DialogProgressComponent from "../common/DialogProgressComponent";
import { TipperStatisticsModel } from "../../types/Tipp";

import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, registerables} from 'chart.js';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { CloseOutlined } from "@mui/icons-material";

ChartJS.register(...registerables, ChartDataLabels);

export interface Props {
    open: boolean;
    userSaisonAllocId: string;
    onClose: () => void;
}

function TipperStatisticsDialogComponent(props:Props) {

    const { open, onClose } = props;
    const [ model, setModel ] = React.useState<TipperStatisticsModel>();

    React.useEffect(function() {
        
      setModel(undefined)

        if(props.userSaisonAllocId) {
            HttpService.get('/SaisonAPI/TipperStatistics', {
                params: {
                    userSaisonAllocId: props.userSaisonAllocId,  
                }
            }
            ).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log("/SaisonAPI/TipperStatistics", res.data);
                }       
                setModel(res.data);
            });
        }

    }, [props.userSaisonAllocId])

    const handleClose = () => {
      //setModel(undefined)
      onClose();
    }

    const optionsTipps = {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'TIPPS',
          },
          datalabels: {
            // anchor: 'end' as const,
            // align: 'end' as const,
            font: {
                size: 16
            },
            formatter: function(value:any, context:Context) {
              if(model?.tipps.length === 0)
                return ''

              if(!value || value === '0')
                return ''

              return Math.round(value) + ' %';
            }
          },
        }
    }

    const noDataTipps = {
      datasets: [
        {
          label: '',
          data: [1],
          backgroundColor: [
            'rgba(164, 164, 164, 0.2)'
          ]
        }
      ]
    }

    const dataTipps = {
        labels: ['Heim', 'Gast', 'Remis'],
        datasets: [
          {
            label: 'Tipps %',
            data: [model?.homeTippsPercent, model?.guestTippsPercent, model?.drawTippsPercent],
            backgroundColor: [
              'rgba(34, 139, 34, 0.2)',
              'rgba(252, 66, 66, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(34, 139, 34, 1)',
              'rgba(252, 66, 66, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
          },
        ],
    };

    const optionsPoints = {
        responsive: true,
        plugins: {
        legend: {
            display: true,
            },
          title: {
            display: true,
            text: 'PUNKTE',
          },
          datalabels: {
            // anchor: 'end' as const,
            // align: 'end' as const,
            font: {
                size: 16
            },
            formatter: function(value:any, context:Context) {
              if(!value)
                return null

              if(model?.tipps.length === 0)
                return null

              return value;
            }
          },
        }
    }

    const dataPoints = {
        labels: ['Heim', 'Gast', 'Remis'],
        datasets: [
          {
            label: 'Punkte',
            data: [model?.homeTippPoints, model?.guestTippPoints, model?.drawTippPoints],
            backgroundColor: [
              'rgba(34, 139, 34, 0.2)',
              'rgba(252, 66, 66, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(34, 139, 34, 1)',
              'rgba(252, 66, 66, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
          },
        ],
    }

    const optionsTopFlopTeams = {
      indexAxis: 'y' as const,
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'right' as const,
        },
        title: {
          display: true,
          text: 'PUNKTE (TOP 3 / FLOP 3)',
        },
      },
      options: {
          indexAxis: 'y',
          plugins: {
           legend: {
              display: false
           }
          },
          scale: {
            ticks: {
              precision: 0
            }
          }
        },
        scales: {
          y: {
              ticks: {
                  autoSkip: false
              }
          }
      }
    };
    
    const dataTopFlopTeams = {
        labels: model?.top3TeamPoints ? model?.top3TeamPoints.concat(model.flop3TeamPoints).map(s => s.teamName) : ["NaN"],
        datasets: [
            {
                label: '',
                axis: 'y',
                data: model?.top3TeamPoints ? model?.top3TeamPoints.concat(model.flop3TeamPoints).map(s => s.points) : [0],
                fill: false,
                borderWidth: 1, 
                backgroundColor: [
                    'rgba(34, 139, 34, 0.2)',
                    'rgba(34, 139, 34, 0.2)',
                    'rgba(34, 139, 34, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgb(34, 139, 34)',
                    'rgb(34, 139, 34)',
                    'rgb(34, 139, 34)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 99, 132)'
                ],
            }
        ],
    };

    const optionsTopTipps = {
      indexAxis: 'x' as const,
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'right' as const,
        },
        title: {
          display: true,
          text: 'TIPPS (TOP 3 PRO ERGEBNIS)',
        },
      },
      options: {
          indexAxis: 'y',
          plugins: {
           legend: {
              display: false
           }
          },
          scale: {
            ticks: {
              precision: 0
            }
          }
        },
        scales: {
          y: {
              ticks: {
                  autoSkip: false
              }
          }
      }
    };
    
    const labelsTopTipps = new Array<string>();
    const dataItemsTopTipps = new Array<number>();

    if(model?.top3HomeTipps) {
      model?.top3HomeTipps.forEach(tippPoint => {
        labelsTopTipps.push(tippPoint.tipp)
        dataItemsTopTipps.push(tippPoint.points)
      });
    }

    while(labelsTopTipps.length < 3) {
      labelsTopTipps.push('')
      dataItemsTopTipps.push(0)
    }

    if(model?.top3RemisTipps) {
      model?.top3RemisTipps.forEach(tippPoint => {
        labelsTopTipps.push(tippPoint.tipp);
        dataItemsTopTipps.push(tippPoint.points)
      });
    }

    while(labelsTopTipps.length < 6) {
      labelsTopTipps.push('');
      dataItemsTopTipps.push(0)
    }

    if(model?.top3GuestTipps) {
      model?.top3GuestTipps.forEach(tippPoint => {
        labelsTopTipps.push(tippPoint.tipp);
        dataItemsTopTipps.push(tippPoint.points)
      });
    }

    while(labelsTopTipps.length < 9) {
      labelsTopTipps.push('');
      dataItemsTopTipps.push(0)
    }

    const dataTopTipps = {
        labels: labelsTopTipps,
        datasets: [
            {
                label: '',
                axis: 'y',
                data: dataItemsTopTipps,
                fill: false,
                borderWidth: 1, 
                backgroundColor: [
                    'rgba(34, 139, 34, 0.2)',
                    'rgba(34, 139, 34, 0.2)',
                    'rgba(34, 139, 34, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(252, 66, 66, 0.2)',
                    'rgba(252, 66, 66, 0.2)',
                    'rgba(252, 66, 66, 0.2)'
                ],
                borderColor: [
                    'rgba(34, 139, 34, 1)',
                    'rgba(34, 139, 34, 1)',
                    'rgba(34, 139, 34, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(252, 66, 66, 1)',
                    'rgba(252, 66, 66, 1)',
                    'rgba(252, 66, 66, 1)'
                ],
            }
        ],
    };

    return(
        <TipperStatisticsDialog open={open} fullWidth={true}>
            {model?.userSaisonAlloc && (
              <DialogTitle title="STATISTIKEN">STATISTIKEN ({model?.userSaisonAlloc.playerName})</DialogTitle>
            )}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseOutlined />
            </IconButton>
            {!model && (
                <DialogProgressComponent delay={1000} />
            )} 
            <DialogContent>
              {model && (
                <Grid container rowSpacing={4}>
                  <Grid item xs={12}>
                    <Pie options={optionsTipps} data={model?.tipps.length && model?.tipps.length > 0 ? dataTipps : noDataTipps} />  
                  </Grid>
                  <Grid item xs={12}>
                    <Bar options={optionsTopFlopTeams} data={dataTopFlopTeams} height={"220px"}/>  
                  </Grid>
                  <Grid item xs={12}>
                    <Pie options={optionsPoints} data={model?.tipps.length && model?.tipps.length > 0 ? dataPoints : noDataTipps} />  
                  </Grid>
                  <Grid item xs={12}>
                    <Bar options={optionsTopTipps} data={dataTopTipps} height={"220px"}/>  
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>SCHLIESSEN</Button>
            </DialogActions>
        </TipperStatisticsDialog>
    )
}

export default TipperStatisticsDialogComponent

const TipperStatisticsDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))
