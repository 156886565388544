import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App"

export default function HomePage() {
    
    const ctx = useContext(AppContext);
    const nav = useNavigate();

    useEffect(function() {

        if(ctx?.saison) {
            nav("/saison/" + ctx.saison.id + "/home")
        } else if(ctx?.account && ctx?.account.saisonId) {
            nav("/saison/" + ctx.account.saisonId + "/home")
        } else {
            if(ctx?.account) {
                nav("/saisons")
            }    
        }

    })

    return (
        <div>
        </div>
    )
}