import React from "react";
import { SaisonEvent } from "../../types/Saison";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, styled } from "@mui/material";
import HttpService from "../../services/HttpService";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

export interface Props {
    open: boolean;
    saisonId:string
    event?:SaisonEvent;
    onOk: () => void;
    onAbort: () => void;
}

export default function EditEventDialogComponent(props: Props) {

    const [ loading, setLoading ] = React.useState(false);
    const [ title, setTitle ] = React.useState("");
    const [ id, setId ] = React.useState("");
    const [ startDate, setStartDate ] = React.useState("");
    const [ endDate, setEndDate ] = React.useState("");
    const [ editorState, setEditorState ] = React.useState<EditorState>(EditorState.createEmpty());
    const [ location, setLocation ] = React.useState("");

    React.useEffect(() => {
        if(props.event) {
            setTitle(props.event.title);
            setId(props.event.id)
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(props.event.memo))))
            setStartDate(props.event.startDate);
            setEndDate(props.event.endDate)
            setLocation(props.event.location)
        } else {
            reset();
        }
    },[props.event])

    const reset = () => {
        setTitle("");
        setId("");
        setEditorState(EditorState.createEmpty())
        setStartDate("")
        setEndDate("")
        setLocation("")
    }

    const handleCancel = () => {
        props.onAbort();
    }

    const handleDelete = () => {
        setLoading(true)
        HttpService.delete("/SaisonAPI/Event", {
            params: {eventId: props.event?.id}
        }).then(res => {
            if(res.status === 200) {
                setLoading(false);
                reset();
                props.onOk();     
            } 
        })
    }

    const handleOk = () => {
        setLoading(true)
        HttpService.post("/SaisonAPI/Event", {
            id: id,
            title: title,
            memo: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            startDate: startDate,
            endDate: endDate,
            saisonId: props.saisonId,
            location: location
        }).then(res => {
            if(res.status === 200) {
                setLoading(false);
                reset();
                props.onOk();
            } 
        })
    }

    return (
        <EditEventDialog open={props.open}>
            <DialogTitle>TERMIN</DialogTitle>
            <DialogContent dividers>
                <TextField sx={{marginTop: 3}} size="small" fullWidth id="outlined-basic" label="TITEL" variant="outlined" value={title} onChange={e => setTitle(e.target.value)} />
                <TextField sx={{marginTop: 3}} size="small" fullWidth  InputLabelProps={{shrink: true }} label="START" variant="outlined" placeholder="" value={startDate} onChange={e => setStartDate(e.target.value)} type='datetime-local'/>
                <TextField sx={{marginTop: 3}} size="small" fullWidth  InputLabelProps={{shrink: true }} label="ENDE" variant="outlined" placeholder="" value={endDate} onChange={e => setEndDate(e.target.value)} type='datetime-local'/>
                <TextField sx={{marginTop: 3}} size="small" fullWidth id="outlined-basic" label="ORT" variant="outlined" value={location} onChange={e => setLocation(e.target.value)} />
                <Box sx={{marginTop: 3}}>
                    <Editor 
                        editorStyle={{ height: '200px' }}
                        editorState={editorState} 
                        onEditorStateChange={(editorState) => setEditorState(editorState)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete} disabled={!id}>LÖSCHEN</Button>
                <Button onClick={handleCancel} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleOk} disabled={loading || !title}>ÜBERNEHMEN</Button>
            </DialogActions>
        </EditEventDialog>
    )
}

const EditEventDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))