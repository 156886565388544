import styled from "@emotion/styled"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { SaisonEvent } from "../../types/Saison";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";

export interface Props {
    open: boolean;
    event:SaisonEvent;
    onClose: () => void;
}

export default function EventDetailsDialog(props: Props) {
    return (
        <EventDialog open={props.open}>
            <DialogTitle>{props.event.title}</DialogTitle>
            <DialogContent>
                <TextField sx={{marginTop: 3}} InputProps={{readOnly: true}} size="small" fullWidth  InputLabelProps={{shrink: true }} label="START" variant="outlined" placeholder="" defaultValue={props.event.startDate} type='datetime-local'/>
                <TextField sx={{marginTop: 3}} InputProps={{readOnly: true}} size="small" fullWidth  InputLabelProps={{shrink: true }} label="ENDE" variant="outlined" placeholder="" defaultValue={props.event.endDate} type='datetime-local'/>
                <TextField sx={{marginTop: 3}} InputProps={{readOnly: true}} size="small" fullWidth id="outlined-basic" label="ORT" variant="outlined" defaultValue={props.event.location}  />
                <Editor 
                    readOnly={true}
                    toolbarHidden={true}
                    editorState={props.event.memo ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.event.memo))) : EditorState.createEmpty()}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>SCHLIESSEN</Button>
            </DialogActions>
        </EventDialog>
    )
}

const EventDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))