import { Circle } from "@mui/icons-material";
import { Box, Fade, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import HttpService from "../../services/HttpService";
import { TeamLeagueModel } from "../../types/Saison";
import { useNavigate } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";

function TeamLeagueTableComponent(props: {
    SaisonId:string,
    Team1Id?:string,
    Team2Id?:string,
    TillMatchDay?:number,
    reducerValue?:number,
    onLoad?: (team1?:number, team2?:number) => void
}) {

    
    const [teamLeagueModel, setTeamLeagueModel] = React.useState<TeamLeagueModel>(); 

    const navigate = useNavigate();

    React.useEffect(function() {
        HttpService.get<TeamLeagueModel>(props.TillMatchDay ? '/SaisonAPI/TeamLeagueTableTillMatchDay' : "/SaisonAPI/TeamLeagueTable", {
            params: { saisonId: props.SaisonId, tillMatchDay: props.TillMatchDay }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }

                var _rang = 0
                var _points = 0
                var _number = 0
                var _diff = 0
                var _goals = 0
            
                res.data.teamLeagues.forEach((TeamLeagueItem) => {
            
                    _number++;
            
                    if (!(_points === TeamLeagueItem.points && _diff === TeamLeagueItem.goals - TeamLeagueItem.goalsConceded && _goals === TeamLeagueItem.goals)) {
                        _rang = _number
                    }
                    TeamLeagueItem.rang = _rang
                
                    _points = TeamLeagueItem.points;
                    _diff = TeamLeagueItem.goals - TeamLeagueItem.goalsConceded;
                    _goals = TeamLeagueItem.goals;
            
                })

                setTeamLeagueModel(res.data);

                if(props.onLoad !== undefined) {
                    if(props.Team1Id !== undefined && props.Team2Id !== undefined ) {
                        props.onLoad(
                            res.data?.teamLeagues.find(s => s.teamId === props.Team1Id)?.rang,
                            res.data?.teamLeagues.find(s => s.teamId === props.Team2Id)?.rang
                        )
                    } else {
                        props.onLoad()
                    }   
                }
            });    
    }, [props.SaisonId, props.reducerValue])

    const Team1 = teamLeagueModel?.teams.find(s => s.id === props.Team1Id);
    const Team2 = teamLeagueModel?.teams.find(s => s.id === props.Team2Id);
    
    var rows = [], i = 0, len = 17;
    while (++i <= len) rows.push(i);

    return(
        <div>
            {teamLeagueModel?.teamLeagues ? (
                <Fade in>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right" width={'50px'}>PL.</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>TEAM</TableCell>
                                    <TableCell align="right">SP</TableCell>
                                    <TableCell align="right" sx={{display: {xs: 'none', sm: 'table-cell'}}}>S</TableCell>
                                    <TableCell align="right" sx={{display: {xs: 'none', sm: 'table-cell'}}}>U</TableCell>
                                    <TableCell align="right" sx={{display: {xs: 'none', sm: 'table-cell'}}}>N</TableCell>
                                    <TableCell align="right">TORE</TableCell>
                                    <TableCell align="right">DIFF</TableCell>
                                    <TableCell align="right">PKT</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teamLeagueModel?.teamLeagues.map((TeamLeagueItem) => (
                                    <TableRow key={TeamLeagueItem.teamId} onClick={() => navigate("/team/" + TeamLeagueItem.teamId)} sx={{cursor: 'pointer'}}>
                                        <TableCell >
                                            <Grid container>
                                                <Grid item xs={6} textAlign={'left'}>
                                                    {(TeamLeagueItem.teamId === Team1?.id || TeamLeagueItem.teamId === Team2?.id) && (
                                                        <Circle className="team-league-circle" sx={{ color: 'darkred', fontSize: '0.7rem', marginRight: '7px' }}/>
                                                    )}
                                                </Grid>
                                                <Grid item xs={6} textAlign={'right'}>
                                                    {TeamLeagueItem.rang}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <ImageComponent 
                                                fileName={'/images/upload/teams/' + teamLeagueModel.teams.find(t => t.id === TeamLeagueItem.teamId)?.imageLogoPath} 
                                                base64Thumb={teamLeagueModel.teams.find(t => t.id === TeamLeagueItem.teamId)?.logoBase64}
                                                height={'100%'}
                                                width={'100%'}
                                                sx={{height: '24px', width: '30px', justifyContent: 'center'}}/>
                                        </TableCell>
                                        <TableCell sx={{display: {xs: 'none', sm: 'table-cell'}}}>
                                            {TeamLeagueItem.name}
                                        </TableCell>
                                        <TableCell sx={{display: {sm: 'none'}}}>
                                            {teamLeagueModel.teams.find(t => t.id === TeamLeagueItem.teamId)?.shortName}
                                        </TableCell>
                                        <TableCell align="right">
                                            {TeamLeagueItem.spieltage}
                                        </TableCell>
                                        <TableCell align="right" sx={{display: {xs: 'none', sm: 'table-cell'}}}>
                                            {TeamLeagueItem.won}
                                        </TableCell>
                                        <TableCell align="right" sx={{display: {xs: 'none', sm: 'table-cell'}}}>
                                            {TeamLeagueItem.draw}
                                        </TableCell>
                                        <TableCell align="right" sx={{display: {xs: 'none', sm: 'table-cell'}}}>
                                            {TeamLeagueItem.lost}
                                        </TableCell>
                                        <TableCell align="right">
                                            {TeamLeagueItem.goals}:{TeamLeagueItem.goalsConceded}
                                        </TableCell>
                                        <TableCell align="right">
                                            {TeamLeagueItem.goals - TeamLeagueItem.goalsConceded}
                                        </TableCell>
                                        <TableCell align="right">
                                            {TeamLeagueItem.points}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fade>
            ) : (
                <Box>
                    {rows.map(function (i) {
                        return (
                            <Grid key={i} container rowSpacing={1} columnSpacing={1} marginTop={1} paddingLeft={1} paddingRight={1}>
                                <Grid item xs={1}>
                                    <Skeleton key={i} variant="rectangular" sx={{fontSize: '1.5rem', marginBottom: 1}} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Skeleton key={i} variant="rectangular" sx={{fontSize: '1.5rem', marginBottom: 1}} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton key={i} variant="rectangular" sx={{fontSize: '1.5rem', marginBottom: 1}} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Skeleton key={i} variant="rectangular" sx={{fontSize: '1.5rem', marginBottom: 1}} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Skeleton key={i} variant="rectangular" sx={{fontSize: '1.5rem', marginBottom: 1}} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Skeleton key={i} variant="rectangular" sx={{fontSize: '1.5rem', marginBottom: 1}} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Skeleton key={i} variant="rectangular" sx={{fontSize: '1.5rem', marginBottom: 1}} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Skeleton key={i} variant="rectangular" sx={{fontSize: '1.5rem', marginBottom: 1}} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Skeleton key={i} variant="rectangular" sx={{fontSize: '1.5rem'}} />
                                </Grid>
                            </Grid>
                        )
                    })}
                </Box>
            )}
        </div>
    )
}
export default TeamLeagueTableComponent