import React from "react";
import { Team } from "../../types/Saison";
import { TeamSuggest } from "../../types/Common";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";
import HttpService from "../../services/HttpService";
import { DataGrid, GridColDef, GridRowParams, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";

const TeamSuggestSelectionDialogComponent = (props: {
    
    open:boolean
    onClose: () => void
    onSave: () => void
    Teams:Team[]
    saisonId?:string
    
}) => {

    const [teamSuggests, setTeamSuggests] = React.useState(new Array<TeamSuggest>())
    const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>()

    React.useEffect(() => {
        HttpService.get('/CommonAPI/TeamSuggests'
            ).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }   
                setTeamSuggests(res.data)
                setSelectedRows(teamSuggests.map(s => s.id))
                //setSelectedRows(['0d2989d5-104e-442d-be43-885d0119bbb8'])
            });
    },[])

    const columns: GridColDef[] = [
        { field: 'imageLogoPath', headerName: '', flex: 1, renderCell: (params) => {
        return (
            <Box>
            {params.value && (
                <Box className={props.Teams.find(s => s.teamSuggestId === params.row.id) !== undefined? 'teamlogo-fit teamlogo-disabled' : 'teamlogo-fit'} height={45} minWidth={50} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + params.value + ')'}} />
            )}
            </Box>
        );}
        },
        { field: 'name', headerName: 'NAME', flex: 2 },
        { field: 'shortName', headerName: 'KÜRZEL', flex: 1}
    ]


    const handleCancel = () => {
        setSelectedRows([]);
        props.onClose();
    }

    const handleSave = () => {
        
        const newTeams = Array<Team>();

        selectedRows?.forEach((teamSuggestId) => {
            if(props.Teams === undefined || !props.Teams.find(s => s.teamSuggestId === teamSuggestId)) {
                var TeamSuggest = teamSuggests.find(s => s.id === teamSuggestId);
                if(TeamSuggest !== undefined) {
                    if(props.saisonId) {
                        newTeams.push({
                            id: '',
                            name: TeamSuggest?.name,
                            shortName: TeamSuggest?.shortName,
                            imageLogoPath: TeamSuggest?.imageLogoPath,
                            logoBase64: "",
                            teamSuggestId: TeamSuggest.id,
                            saisonId: props.saisonId
                        })
                    }
                }
            }
        })

        HttpService.post('/SaisonApi/Teams', 
            newTeams
        ).then(res => {
            if(res.status === 200) {
                setSelectedRows([]);
                props.onSave();
            }   
        });

    }

    const handleIsRowSelectable = (params: GridRowParams) => {
        if(props.Teams !== undefined) {
            return props.Teams.find(s => s.teamSuggestId === params.row.id) === undefined
        } 
        return true 
    }

    return (
        <div>
            <TeamSuggestSelectionDialog open={props.open}>
                <DialogTitle>VEREINE</DialogTitle>
                <DialogContent>
                <DataGrid 
                    autoHeight
                    rows={teamSuggests}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 20,
                        },
                    },
                    }}
                    pageSizeOptions={[20]}
                    disableRowSelectionOnClick
                    checkboxSelection                  
                    isRowSelectable={handleIsRowSelectable}
                    rowSelectionModel={selectedRows}
                    onRowSelectionModelChange={(newSelection) => {
                        setSelectedRows(newSelection)
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {debounceMs: 500},
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true }
                        }
                    }}
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>ABBRECHEN</Button>
                    <Button onClick={handleSave}>ÜBERNEHMEN</Button>
                </DialogActions>
            </TeamSuggestSelectionDialog>
        </div>
    )
}
export default TeamSuggestSelectionDialogComponent

const TeamSuggestSelectionDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))