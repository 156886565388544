import styled from "@emotion/styled";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { ManagerStatisticsModel, TeamPlayerStatistics } from "../../types/Saison";
import React from "react";

import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import HttpService from "../../services/HttpService";
import DialogProgressComponent from "../common/DialogProgressComponent";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export interface Props {
    open: boolean;
    saisonId: string;
    userSaisonAllocId: string;
    onClose: () => void;
}

function ManagerStatisticsDialogComponent(props:Props) {

    const { open, onClose } = props;
    const [ model, setModel ] = React.useState<ManagerStatisticsModel>();
    const [ bestPlayers, setBestPlayers ] = React.useState<TeamPlayerStatistics[]>();
    const [ worsePlayers, setWorsePlayers ] = React.useState<TeamPlayerStatistics[]>();

    React.useEffect(function() {
        setModel(undefined)
        setBestPlayers(undefined)
        setWorsePlayers(undefined)
        if(props.userSaisonAllocId) {
            HttpService.get('/SaisonAPI/ManagerStatistics', {
                params: {
                    userSaisonAllocId: props.userSaisonAllocId,
                    saisonId: props.saisonId        
                }
            }
            ).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }       
                setModel(res.data.model);
                setBestPlayers(res.data.bestPlayers)
                setWorsePlayers(res.data.worsePlayers) 
            });
  
        }
    }, [props.saisonId, props.userSaisonAllocId])

    const handleClose = () => {
        onClose();
    }

    const topFlopPlayers:string[] = [];
    const topFlopPlayersPoints:number[] = [];

    if(bestPlayers) {
        bestPlayers.forEach(bestPlayer => {
            let teamPlayer = model?.teamPlayers.find(s => s.id === bestPlayer.teamPlayerId)
            if(teamPlayer) {
                topFlopPlayers.push(teamPlayer?.name)   
            }
            topFlopPlayersPoints.push(bestPlayer.points)
        });
    }

    if(worsePlayers) {
        worsePlayers.forEach(worsePlayer => {
            let teamPlayer = model?.teamPlayers.find(s => s.id === worsePlayer.teamPlayerId)
            if(teamPlayer) {
                topFlopPlayers.push(teamPlayer?.name)   
            }
            topFlopPlayersPoints.push(worsePlayer.points)
        });
    }

    const options = {
        indexAxis: 'y' as const,
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'right' as const,
          },
          title: {
            display: true,
            text: 'TOP / FLOP SPIELER',
          },
        },
        options: {
            indexAxis: 'y',
            plugins: {
             legend: {
                display: false
             }
            },
            scale: {
              ticks: {
                precision: 0
              }
            }
          },
          scales: {
            y: {
                ticks: {
                    autoSkip: false
                }
            }
        }
      };
      
    const data = {
        labels: topFlopPlayers,
        datasets: [
            {
                label: '',
                axis: 'y',
                data: topFlopPlayersPoints,
                fill: false,
                borderWidth: 1, 
                backgroundColor: [
                    'rgba(34, 139, 34, 0.2)',
                    'rgba(34, 139, 34, 0.2)',
                    'rgba(34, 139, 34, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgb(34, 139, 34)',
                    'rgb(34, 139, 34)',
                    'rgb(34, 139, 34)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 99, 132)'
                ],
            }
        ],
    };

    return(
        <ManagerStatisticsDialog open={open} fullWidth={true}>
            <DialogTitle title="STATISTIKEN">STATISTIKEN ({model?.userSaisonAlloc.playerName})</DialogTitle>
            {!model && (
                <DialogProgressComponent delay={1000} />
            )} 
            <DialogContent>
                <Bar options={options} data={data} height={'220px'}/>  
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>SCHLIESSEN</Button>
            </DialogActions>
        </ManagerStatisticsDialog>
    )
}

export default ManagerStatisticsDialogComponent

const ManagerStatisticsDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))