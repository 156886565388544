import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import React from "react";
import HttpService from "../../services/HttpService";
import { MatchDayMatch, MatchDayTeam, MatchEventType } from "../../types/MatchDay"
import { LineUpItem, Team, TeamPlayer } from "../../types/Saison";
import PositionIcon from "../common/PositionIconComponent";
import RedCard from "../../res/images/RedCard.png";
import YellowRedCard from "../../res/images/YellowRedCard.png";
import SoccerBall  from "../../res/images/SoccerBall.png"
import DialogProgressComponent from "../common/DialogProgressComponent";

export interface Props {
    open:boolean;
    matchDayMatch:MatchDayMatch;
    matchDayMatchTeams:MatchDayTeam[];
    teams:Team[];
    teamPlayers:TeamPlayer[];
    lineUpItems:LineUpItem[];
    onClose: (reload:boolean) => void
}

function MatchEventDialogComponent(props:Props) {

    const [eventTyp, setEventType] = React.useState<MatchEventType>(MatchEventType.GOAL);
    const [selectedTeam, setSelectedTeam] = React.useState("");
    const [selectedTeamPlayerGoal, setSelectedTeamPlayerGoal] = React.useState("");
    const [selectedTeamPlayerAssist, setSelectedTeamPlayerAssist] = React.useState("");
    const [selectedTeamPlayerCard, setSelectedTeamPlayerCard] = React.useState("");
    const [filteredTeamPlayers, setFilteredTeamPlayers] = React.useState<Array<TeamPlayer>>(new Array<TeamPlayer>());
    const [loading, setLoading] = React.useState(false);
    
    var saveable = false;

    if(eventTyp === MatchEventType.GOAL) {
        if(selectedTeam !== "") {
            saveable = true;
        }
    }

    if(eventTyp === MatchEventType.RED_CARD || eventTyp === MatchEventType.YELLOW_RED_CARD) {
        if(selectedTeamPlayerCard !== "") {
            saveable = true;
        }
    }

    const handleOk = () => {

        setLoading(true)

        HttpService.post('/MatchDayAPI/MatchEvent', 
        {
            MatchDayMatchId : props.matchDayMatch.id,
            MatchEventType : eventTyp, 
            MatchDayTeamId : props.matchDayMatchTeams.find(s => s.teamId === selectedTeam)?.id,
            LineUpItemIdGoal : props.lineUpItems.find(s => s.teamPlayerId === selectedTeamPlayerGoal)?.id,
            LineUpItemIdAssist : props.lineUpItems.find(s => s.teamPlayerId === selectedTeamPlayerAssist)?.id,
            LineUpItemIdCard : props.lineUpItems.find(s => s.teamPlayerId === selectedTeamPlayerCard)?.id

        }).then(res => {

            if(res.status === 200) {
                setEventType(MatchEventType.GOAL)
                setSelectedTeam("");
                setSelectedTeamPlayerAssist("")
                setSelectedTeamPlayerGoal("")
                setSelectedTeamPlayerCard("")
                props.onClose(true)
            }  
            setLoading(false)
        })
    }
    
    const handleCancel = () => {
        setEventType(MatchEventType.GOAL)
        setSelectedTeam("");
        setSelectedTeamPlayerAssist("")
        setSelectedTeamPlayerGoal("")
        setSelectedTeamPlayerCard("")
        setLoading(false)
        props.onClose(false)
    }

    const handleOnChangeTyp = (event: SelectChangeEvent<MatchEventType>) => {
        setEventType(event.target.value as MatchEventType)
        if(event.target.value as MatchEventType === MatchEventType.RED_CARD || event.target.value as MatchEventType === MatchEventType.YELLOW_RED_CARD) {
            setFilteredTeamPlayers(props.teamPlayers.sort((a, b) => a.position > b.position ? 1 : -1))
        }
    }

    const handleOnChangeTeam = (event: SelectChangeEvent) => {
        setFilteredTeamPlayers(props.teamPlayers.filter(s => s.teamId === event.target.value).sort((a, b) => a.position > b.position ? 1 : -1))
        setSelectedTeam(event.target.value)
        setSelectedTeamPlayerGoal("");
    }

    const handleOnChangeTeamPlayerGoal = (event:SelectChangeEvent) => {
        setSelectedTeamPlayerGoal(event.target.value)
    }

    const handleOnChangeTeamPlayerAssist = (event:SelectChangeEvent) => {
        setSelectedTeamPlayerAssist(event.target.value)
    }

    const handleOnChangeTeamPlayerCard = (event:SelectChangeEvent) => {
        setSelectedTeamPlayerCard(event.target.value)
    }

    return (
        <MatchEventDialog fullWidth={true} open={props.open}>
            <DialogTitle>EREIGNIS</DialogTitle>
            {loading && (
                <DialogProgressComponent delay={1000} />
            )}
            <DialogContent dividers>
                <InputLabel>Typ</InputLabel>
                <Select fullWidth={true} value={eventTyp} size="small" onChange={handleOnChangeTyp}>
                    <MenuItem value={MatchEventType.GOAL}>
                        <Grid container wrap="nowrap">
                            <Grid item marginRight={'5px'}>
                                <Box className='teamlogo-fit' textAlign='left' height={'25px'} width={'25px'} sx={{backgroundImage:'url(' + SoccerBall + ')'}}></Box>
                            </Grid>
                            <Grid item>
                                TOR
                            </Grid>
                        </Grid>
                    </MenuItem>
                    <MenuItem value={MatchEventType.RED_CARD}>
                        <Grid container wrap="nowrap">
                            <Grid item marginRight={'5px'}>
                                <Box className='teamlogo-fit' textAlign='left' height={'25px'} width={'25px'} sx={{backgroundImage:'url(' + RedCard + ')'}}></Box>
                            </Grid>
                            <Grid item>
                                ROTE KARTE
                            </Grid>
                        </Grid>
                    </MenuItem>
                    <MenuItem value={MatchEventType.YELLOW_RED_CARD}>
                        <Grid container wrap="nowrap">
                            <Grid item marginRight={'5px'}>
                                <Box className='teamlogo-fit' textAlign='left' height={'25px'} width={'25px'} sx={{backgroundImage:'url(' + YellowRedCard + ')'}}></Box>
                            </Grid>
                            <Grid item>
                                GELB/ROTE KARTE                            
                            </Grid>
                        </Grid>      
                    </MenuItem>
                </Select>
                {eventTyp === MatchEventType.GOAL && (
                    <Box>
                        <InputLabel>TOR FÜR</InputLabel>
                        <Select fullWidth={true} size="small" value={selectedTeam} onChange={handleOnChangeTeam}>
                            <MenuItem value={props.teams.find(s => s.id === props.matchDayMatchTeams.find(s => s.id === props.matchDayMatch.matchDayTeamsId1)?.teamId)?.id}>
                                <Grid container>
                                    <Grid item marginRight={'5px'}>
                                        <Box className='teamlogo-fit' height={'20px'} width={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.teams.find(s => s.id === props.matchDayMatchTeams.find(s => s.id === props.matchDayMatch.matchDayTeamsId1)?.teamId)?.imageLogoPath + ')'}}>&nbsp;
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        {props.teams.find(s => s.id === props.matchDayMatchTeams.find(s => s.id === props.matchDayMatch.matchDayTeamsId1)?.teamId)?.name}
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value={props.teams.find(s => s.id === props.matchDayMatchTeams.find(s => s.id === props.matchDayMatch.matchDayTeamsId2)?.teamId)?.id}>
                                <Grid container>
                                    <Grid item marginRight={'5px'}>
                                        <Box className='teamlogo-fit' height={'20px'} width={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.teams.find(s => s.id === props.matchDayMatchTeams.find(s => s.id === props.matchDayMatch.matchDayTeamsId2)?.teamId)?.imageLogoPath + ')'}}>&nbsp;
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        {props.teams.find(s => s.id === props.matchDayMatchTeams.find(s => s.id === props.matchDayMatch.matchDayTeamsId2)?.teamId)?.name}
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        </Select>
                        <InputLabel>TORSCHÜTZE</InputLabel>
                        <Select fullWidth={true} size="small" value={selectedTeamPlayerGoal} onChange={handleOnChangeTeamPlayerGoal} disabled={selectedTeam === ""}>
                            {filteredTeamPlayers.map((TeamPlayer) => (
                                <MenuItem key={TeamPlayer.id} value={TeamPlayer.id}>
                                    <Grid container>
                                        <Grid item marginRight={'5px'}>
                                            {/* <ShirtComponent teamPlayerPosition={TeamPlayer.position} number={TeamPlayer.number} height="24px" width="24px" /> */}
                                            <PositionIcon position={TeamPlayer.position} />
                                        </Grid>
                                        <Grid item>
                                            {TeamPlayer.name} {TeamPlayer.number ? '(' + TeamPlayer.number + ')' : ''}
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                        <InputLabel>VORBEREITUNG</InputLabel>
                        <Select fullWidth={true} size="small" value={selectedTeamPlayerAssist} onChange={handleOnChangeTeamPlayerAssist} disabled={selectedTeam === ""}>
                            {filteredTeamPlayers.map((TeamPlayer) => (
                                <MenuItem key={TeamPlayer.id} value={TeamPlayer.id}>
                                    <Grid container>
                                        <Grid item marginRight={'5px'}>
                                            {/* <ShirtComponent teamPlayerPosition={TeamPlayer.position} number={TeamPlayer.number} height="24px" width="24px" /> */}
                                            <PositionIcon position={TeamPlayer.position} />
                                        </Grid>
                                        <Grid item>
                                            {TeamPlayer.name} {TeamPlayer.number ? '(' + TeamPlayer.number + ')' : ''}
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                )}
                {(eventTyp === MatchEventType.RED_CARD || eventTyp === MatchEventType.YELLOW_RED_CARD) && (
                    <Box>
                        <InputLabel>SPIELER</InputLabel>
                        <Select fullWidth={true} size="small" value={selectedTeamPlayerCard} onChange={handleOnChangeTeamPlayerCard}>
                            {filteredTeamPlayers.map((TeamPlayer) => (
                                <MenuItem key={TeamPlayer.id} value={TeamPlayer.id}>
                                    <Grid container>
                                        <Grid item marginRight={'5px'}>
                                            <PositionIcon position={TeamPlayer.position} />
                                        </Grid>
                                        <Grid item>
                                            {TeamPlayer.name} {TeamPlayer.number ? '(' + TeamPlayer.number + ')' : ''}
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleOk} disabled={!saveable || loading}>ÜBERNEHMEN</Button>
            </DialogActions>
        </MatchEventDialog>
    )
}

export default MatchEventDialogComponent

const MatchEventDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))