import { DataGrid, GridColDef, GridEventListener, GridToolbar } from "@mui/x-data-grid"
import React from "react";
import HttpService from "../services/HttpService";
import { TeamSuggest } from "../types/Common";
import { Box, Card, CardHeader, Fab } from "@mui/material";
import { Add } from "@mui/icons-material";
import { AppContext } from "../App";
import TeamSuggestDialogComponent from "../components/common/TeamSuggestDialogComponent";

const TeamsPage = () => {

    const [ reducerValue, forceUpdate ] = React.useReducer(x => x + 1, 0);
    const [ teamSuggests, setTeamSuggests ] = React.useState(new Array<TeamSuggest>())
    const [ openDialog, setOpenDialog ] = React.useState(false);
    const [ selectedTeam, setSelectedTeamSuggest ] = React.useState();

    const columns: GridColDef[] = [
        { field: 'imageLogoPath', headerName: '', renderCell: (params) => {
        return (
            <Box>
            {params.value && (
            <Box className='teamlogo-fit' height={30} minWidth={50} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + params.value + ')'}} />
            )}
            </Box>
        );}
        },
        { field: 'name', headerName: 'NAME', width: 200 },
        { field: 'shortName', headerName: 'KÜRZEL', width: 100 }
    ]

    const ctx = React.useContext(AppContext)

    React.useEffect(function() {

        window.scrollTo(0, 0);

        HttpService.get('/CommonAPI/TeamSuggests'
            ).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }   
                setTeamSuggests(res.data)
            });
      },[reducerValue])

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        setSelectedTeamSuggest(params.row)
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleSaveDialog = () => {
        setOpenDialog(false)
        forceUpdate()
    }

    const handleCreateTeamSuggest = () => {
        setSelectedTeamSuggest(undefined)
        setOpenDialog(true);
    }

    return (
        <div>
            <Card>
            <CardHeader title="VEREINE" />
            <Box sx={{ width: '100%' }}>
                <DataGrid 
                    rowHeight={35}
                    onRowClick={handleRowClick}
                    sx={{
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                    },
                    marginBottom: '50px',
                    fontSize: '1rem'
                    }}
                    autoHeight
                    rows={teamSuggests}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 20,
                        },
                    },
                    }}
                    pageSizeOptions={[20]}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {debounceMs: 500},
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true }
                        }
                    }}
                />  
                {ctx?.account?.roles.find(s => s === "ServerAdmin") && (
                    <Box>
                        <Fab color="primary" aria-label="add" size="small" onClick={handleCreateTeamSuggest} sx={{position: 'fixed',  bottom: (ctx?.saison ? {xs: '66px', md: '16px'} : '16px'), right: '16px'}}>
                            <Add />
                        </Fab>
                    </Box>
                )}
                <TeamSuggestDialogComponent open={openDialog} onClose={handleCloseDialog} onSave={handleSaveDialog} teamSuggest={selectedTeam}/>
            </Box>
            </Card>
        </div>
    )
}

export default TeamsPage