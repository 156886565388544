import { ChatBubble, ChatBubbleOutline } from '@mui/icons-material'
import { Box, Checkbox, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import HttpService from '../../services/HttpService'
import { MatchDayMatch, MatchDayTeam } from '../../types/MatchDay'
import { calculatePoints, Card, LineUp, LineUpItem, Team, TeamPlayer, UserSaisonAlloc } from '../../types/Saison'
import LineUpItemCommentDialogComponent from './LineUpItemCommentDialogComponent'
import TeamPlayerStatisticsDialogComponent from '../saison/TeamPlayerStatisticsDialogComponent'
import ShirtComponent from '../common/ShirtComponent'
import moment from "moment"

function LineUpComponent(props: {
        lineUp?:LineUp,
        lineUpItems:LineUpItem[],
        userSaisonAlloc?:UserSaisonAlloc,
        teamPlayers:TeamPlayer[],
        teams:Team[],
        matchDayTeams:MatchDayTeam[],
        own:boolean,
        matchDayMatches:MatchDayMatch[]
        serverDateTime:string
    }) {

    const [openLineUpItemCommentDialog, setOpenLineUpItemCommentDialog] = React.useState(false);
    const [selectedTeamPlayer, setSelectedTeamPlayer] = React.useState<TeamPlayer>();
    const [selectedLineUpItem, setSelectedLineUpItem ] = React.useState<LineUpItem>();
    const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);

    props.lineUpItems.forEach((lineUpItem) => {
        calculatePoints(lineUpItem)
    })

    const handleOpenCommentDialog = (lineUpItem:LineUpItem) => {
        setSelectedTeamPlayer(props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId))
        setOpenLineUpItemCommentDialog(true);
    };

    const handleCloseCommentDialog = () => {
        setOpenLineUpItemCommentDialog(false);
    }

    const handleOpenTeamPlayerDetails = (lineUpItem?:LineUpItem) => {
        setSelectedLineUpItem(lineUpItem)
        setSelectedTeamPlayer(props.teamPlayers.find(s => s.id === lineUpItem?.teamPlayerId))
        setOpenDetailsDialog(true)
    }

    const matchDayMatch = (lineUpItem:LineUpItem) : MatchDayMatch|undefined => {
        var teamPlayer = props.teamPlayers.find(s => s.id === lineUpItem.teamPlayerId)
        var matchDayTeam = props.matchDayTeams.find(s => s.teamId === teamPlayer?.teamId)
        var matchDayMatch = props.matchDayMatches.find(s => s.matchDayTeamsId1 === matchDayTeam?.id || s.matchDayTeamsId2 === matchDayTeam?.id)

        return matchDayMatch
    }

    const sxProps:SxProps = {
        display: {
            xs: 'none',
            sm: 'table-cell'
        } ,
        padding: '0px'
    }

    return (
        <TableContainer sx={{overflowY: 'hidden', paddingBottom: 1}}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>NAME</TableCell>
                        <TableCell align='center' sx={sxProps}>VEREIN</TableCell>
                        <TableCell align='right' sx={{paddingLeft: '3px', paddingRight: '3px'}}>G</TableCell>
                        <TableCell align='right' sx={{paddingLeft: '3px', paddingRight: '3px'}}>V</TableCell>
                        <TableCell align='right' sx={{paddingLeft: '3px', paddingRight: '3px'}}>T</TableCell>
                        <TableCell align='right'>N</TableCell>
                        <TableCell align='center' sx={sxProps}>KA</TableCell>
                        <TableCell align='center' sx={sxProps}>SP</TableCell>
                        <TableCell align='center' sx={sxProps}>TDT</TableCell>
                        <TableCell align='center' sx={sxProps}>SDT</TableCell>
                        <TableCell align='right'>P</TableCell>
                        {props.own && (
                            <TableCell align='center'></TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.lineUpItems.map((lineUpItem) => 
                        // lineUpItem.teamPlayerId &&
                        // (
                        <TableRow 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                            key={lineUpItem.id}
                            >
                            <TableCell padding={'none'} sx={{cursor: lineUpItem.teamPlayerId ? 'pointer' : '', paddingLeft: '0px', paddingRight: '3px', width: '40px'}} onClick={() => handleOpenTeamPlayerDetails(lineUpItem)}>
                                <ShirtComponent 
                                    teamPlayerPosition={props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.position} 
                                    number={props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.number} 
                                    showPosition={true}
                                    lineUpItem={lineUpItem}
                                    hasPlayed={moment(matchDayMatch(lineUpItem)?.matchDate).isBefore(props.serverDateTime)}
                                    />
                            </TableCell>
                            <TableCell sx={{cursor: lineUpItem.teamPlayerId ? 'pointer' : '', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => handleOpenTeamPlayerDetails(lineUpItem)} className={lineUpPlayerNameClass(lineUpItem)}>
                                {lineUpPlayerName(lineUpItem, props.teamPlayers)}
                            </TableCell>
                            <TableCell sx={sxProps}>
                                {lineUpItem.teamPlayerId && (
                                    <Box className='teamlogo-fit' sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.teams.find(t => t.id === props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.teamId)?.imageLogoPath + ')'}} />
                                )}
                            </TableCell>

                            <TableCell align='right' sx={{paddingLeft: '3px', paddingRight: '3px'}}>
                                {(props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.position === 2 || props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.position === 1) && (
                                    props.matchDayTeams.find(mdt => mdt.teamId === props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.teamId)?.goalsConceded
                                )}
                            </TableCell>
                            <TableCell align='right' sx={{paddingLeft: '3px', paddingRight: '3px'}}>{lineUpItem.assists}</TableCell>
                            <TableCell align='right' sx={{paddingLeft: '3px', paddingRight: '3px'}}>{lineUpItem.goals}</TableCell>
                            <TableCell align='right' sx={{paddingLeft: '3px', paddingRight: '3px'}}>{lineUpItem.grade}</TableCell>
                            <TableCell align='center' sx={sxProps}>
                            {lineUpItem?.card === 2 && (  
                                <Box className='teamlogo-fit' height={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/YellowRedCard.png)'}}>&nbsp;
                                </Box>
                            ) || 
                            lineUpItem?.card === 3 && ( 
                                <Box className='teamlogo-fit' height={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/RedCard.png)'}}>&nbsp;
                                </Box>
                            )}
                            </TableCell>
                            <TableCell align='center' sx={sxProps}><Checkbox disabled sx={{fontSize: 'px', padding: 0}} checked={lineUpItem.playerOfMatch} /></TableCell>
                            <TableCell align='center' sx={sxProps}><Checkbox disabled sx={{fontSize: 'px', padding: 0}} checked={lineUpItem.teamOfDay} /></TableCell>
                            <TableCell align='center' sx={sxProps}><Checkbox disabled sx={{fontSize: 'px', padding: 0}} checked={lineUpItem.playerOfDay} /></TableCell>
                            <TableCell align='right' sx={{paddingLeft: '3px', paddingRight: '3px'}}>{lineUpItem.points}</TableCell>
                            {props.own && (
                                <TableCell align='center' sx={{padding: 0}}>
                                    {lineUpItem.teamPlayerId && props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.note && (
                                        <ChatBubble fontSize='small' sx={{cursor: 'pointer'}} onClick={() => handleOpenCommentDialog(lineUpItem)} />
                                    )}
                                    {lineUpItem.teamPlayerId && !props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.note && (
                                        <ChatBubbleOutline fontSize='small' sx={{cursor: 'pointer'}} onClick={() => handleOpenCommentDialog(lineUpItem)} />
                                    )}
                                    {!lineUpItem.teamPlayerId && (
                                        <ChatBubbleOutline  fontSize='small' color="disabled"  />
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                        )}
                </TableBody>
            </Table>
            <LineUpItemCommentDialogComponent open={openLineUpItemCommentDialog} onClose={handleCloseCommentDialog} selectedTeamPlayer={selectedTeamPlayer}  />
            {selectedTeamPlayer && (
                <TeamPlayerStatisticsDialogComponent
                    onClose={() => setOpenDetailsDialog(false)}
                    open={openDetailsDialog}
                    lineUpItem={selectedLineUpItem}
                    teamPlayerId={selectedTeamPlayer?.id}
                    />
                )}
        </TableContainer>
    )
}

function lineUpPlayerName(lineUpItem:LineUpItem, teamPlayers:TeamPlayer[]) {

    let result = teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.name

    // if(teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.number) {
    //     result = teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.name + " (" + teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)?.number + ")"
    // }

    // if(lineUpItem.notPlayed) {
    //     return result + " (F)"
    // }
    // if(lineUpItem.substitute) {
    //     return result + " (BK)"
    // }
    return result
}

function lineUpPlayerNameClass(lineUpItem:LineUpItem) {
    var result = "";
    if(lineUpItem.teamOfDay) {
        result += "team-of-day "
    }
    if(lineUpItem.playerOfDay) {
        result += "player-of-day "
    }
    if(lineUpItem.playerOfMatch) {
        result += "player-of-match "
    }
    if(lineUpItem.notPlayed) {
        result += "player-not-played "
    }
    return result;
}

export default LineUpComponent