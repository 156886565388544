import { LinearProgress } from "@mui/material";
import { Fade } from "react-awesome-reveal";

export default function DialogProgressComponent(props: {delay:number}) {
    return (
        <Fade delay={props.delay}>
            <LinearProgress></LinearProgress>
        </Fade>
    )
}

