import React, { useContext } from "react";
import HttpService from "../services/HttpService";
import { EternalTablePlayerModel } from "../types/Common";
import Loader from "../components/common/Loader";
import { Box, Card, CardContent, CardHeader, Fade } from "@mui/material";
import { DataGrid, GridColDef, GridRowClassNameParams } from "@mui/x-data-grid";
import { AppContext } from "../App";

export default function HallOfFame() {

    const [ eternalTablePlayer, setEternalTablePlayer ] = React.useState<EternalTablePlayerModel[]>();

    const ctx = useContext(AppContext)

    React.useEffect(function() {
        HttpService.get('/CommonAPI/HallOfFame'
            ).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }       
                setEternalTablePlayer(res.data);      
            });    
    }, [])

    const columns: GridColDef[] = [

        { field: '#', headerName: '#', renderCell: (params) => {
            console.log(params)
            return (
                <Box>{params.api.getRowIndexRelativeToVisibleRows(params.rowNode.id) + 1}</Box>
            )
        }
        },

        { field: 'playerName', headerName: 'NAME'},
        { field: 'saisons', headerName: 'SAISONS', align: 'right', headerAlign: 'right'},
        { field: 'wonSaisons', headerName: 'GEWONNEN', align: 'right', headerAlign: 'right'},
        { field: 'matchDays', headerName: 'SPIELTAGE', align: 'right', headerAlign: 'right'},
        { field: 'goals', headerName: 'TORE', align: 'right', headerAlign: 'right'},
        { field: 'points', headerName: 'PUNKTE', align: 'right', headerAlign: 'right' },
        { field: 'avgGoals', headerName: '∅ TORE', align: 'right', headerAlign: 'right' },
        { field: 'avgPoints', headerName: '∅ PUNKTE', align: 'right', headerAlign: 'right' },
        { field: 'sumRedCards', headerName: 'ROTE KARTEN', align: 'right', headerAlign: 'right' },
        { field: 'sumYellowRedCards', headerName: 'GELB/ROTE KARTEN', align: 'right', headerAlign: 'right' },

        { field: 'sumNotPlayed', headerName: 'F', align: 'right', headerAlign: 'right' },
        { field: 'sumSubstitute', headerName: 'BK', align: 'right', headerAlign: 'right' },
        { field: 'sumTeamOfDay', headerName: 'TDT', align: 'right', headerAlign: 'right' },
        { field: 'sumPlayerOfMatch', headerName: 'SdS', align: 'right', headerAlign: 'right' },
        { field: 'sumPlayerOfDay', headerName: 'SdT', align: 'right', headerAlign: 'right' },


    ]

    const handleGetRowClassName = (params:GridRowClassNameParams<any>) => {
        return params.id === ctx?.account?.id ? 'me-self' : ''
    }

    return (
        <div>
            {eternalTablePlayer && (
                <Fade in>
                    <Card>
                        <CardHeader title="HALL OF FAME" />
                        <CardContent>
                        <Box sx={{ width: '100%' }}>
                            <DataGrid 
                                getRowClassName={(params) =>  handleGetRowClassName(params)}
                                autoHeight
                                sx={{fontSize: '1rem'}}
                                rowHeight={35}
                                rows={eternalTablePlayer}
                                columns={columns}
                                disableRowSelectionOnClick
                                getRowId={(row) => row.userId}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'avgPoints', sort: 'desc' }],
                                    },
                                }}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Fade>
            )}
            {!eternalTablePlayer && (
                <Loader delay={1500}>Spieler werden geladen...</Loader>
            )}
        </div>
    )
}