import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, Slider, TextField, styled } from "@mui/material";
import { Account } from "../../types/Common";
import HttpService from "../../services/HttpService";
import AvatarEditor from 'react-avatar-editor'
import React from "react";
import DialogProgressComponent from "./DialogProgressComponent";
import ImageComponent from "./ImageComponent";

const AccountDialogComponent = (props: {

    open:boolean
    onClose: () => void
    onSave: () => void
    account:Account

}) => {

    const [avatarPath, setAvatarPath] = React.useState("");
    const [avatar, setAvatar] = React.useState<File>();
    const [scale, setScale] = React.useState(1);
    const [account, setAccount] = React.useState(props.account);
    const [loading, setLoading] = React.useState(false);

    const editor = React.useRef<AvatarEditor>(null);

    React.useEffect(() => {
        
        reset();

    }, [props.account])

    const reset = () => {
        setScale(1)
        setAvatarPath("");
        setAvatar(undefined);
        setLoading(false)
    }

    const handleCancel = () => {
        reset();
        props.onClose();
    }

    const handleDeleteAvatar = () => {
        HttpService.get('/AccountAPI/DeleteAvatar', {
            params: { userId: account.id }
            }).then(res => {
                if(res.status === 200) {
                    props.account.imageFileName = ""
                    account.imageFileName = ""
                    reset();
                    props.onClose();
                }   
            });
    }

    const handleSave = () => {

        if(avatarPath) {

            setLoading(true);

            editor.current?.getImage().toBlob(blob => {

                if(blob && avatar) {

                    var formData = new FormData();

                    formData.append("avatar", blob, avatar.name)
                    formData.append("id", account.id)

                    HttpService.post('/AccountAPI/Account', 
                    formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data'
                        }}
                    ).then(res => {
                        if(res.status === 200) {
                            setAccount(res.data)
                            props.account.imageFileName = res.data.imageFileName
                            props.onSave()
                            reset()
                        }   
                    });
                }

            })

        } else {
            props.onSave()
            reset()
        }

    }

    const handleCapture = (event:any) => {
        setAvatarPath(URL.createObjectURL(event.target.files[0]))
        setAvatar(event.target.files[0])
    }

    const handleScale = (event:any) => {
        const scale = parseFloat(event.target.value)
        setScale(scale)
    }

    return (
        <AccountDialog open={props.open}>
            <DialogTitle display={'flex'} flexDirection={'row'}>
                <ImageComponent 
                    className= {`avatar `} 
                    width='100%'
                    sx={{width: '32px'}}
                    fileName={'/images/upload/avatar/' + account.imageFileName} />
                <Box marginLeft={2}>{account.userName}</Box>
            </DialogTitle>
            {loading && (
                  <DialogProgressComponent delay={1000} />
            )}
            <DialogContent sx={{textAlign: 'center'}}>
            <AvatarEditor
                style={{width: '100%', height: '100%', maxWidth: '400px', maxHeight: '400px'}}

                ref={editor}
                image={avatarPath ? avatarPath : HttpService.baseUrl + '/images/upload/avatar/' + account.imageFileName}
                borderRadius={100}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={scale}
                rotate={0}
            />
            {avatarPath && (
                <Box>
                    {/* <InputLabel>ZOOM</InputLabel>
                    <Slider placeholder="" name="Zoom" min={0.1} max={5} step={0.01} defaultValue={1} aria-label="Default" valueLabelDisplay="auto" onChange={handleScale} /> */}
                </Box>
            )}
            <TextField sx={{marginTop: 3}} fullWidth={true} variant="outlined" size="small" type="file" onChange={handleCapture}/>
            </DialogContent>
            <DialogActions>
                {account.imageFileName && (
                    <Button onClick={handleDeleteAvatar} disabled={loading}>LÖSCHEN</Button>
                )}
                <Button onClick={handleCancel} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleSave} disabled={loading}>ÜBERNEHMEN</Button>
            </DialogActions>
        </AccountDialog>
    )

}

export default AccountDialogComponent

const AccountDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))