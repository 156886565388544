import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, TextField } from "@mui/material";
import React from "react";
import HttpService from "../../services/HttpService";
import { useSearchParams } from "react-router-dom";
import DialogProgressComponent from "./DialogProgressComponent";

export default function LoginDialog(props: { open: boolean; onClose: Function; onLogin: Function; }) {

    const [searchParams, setSearchParams] = useSearchParams();

    const codeParam = searchParams.get("code")
    const emailParam = searchParams.get("email")

    const {open, onClose, onLogin} = props;
    const [loginMessage, setLoginMessage] = React.useState<string>();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [type, setType] = React.useState<Type>(Type.login);
    const [confirmPassword, setConfirmPassword] = React.useState("");

    React.useEffect(function() {

      if(codeParam !== null && emailParam !== null) {
        setType(Type.resetPassword)
      }

    }, []);

    const handleClose = () => {
      if(type === Type.forgotPassword) {
        setType(Type.login)
        setLoginMessage(undefined)
      } else {
        onClose();
      }
    };

    const handleLogin = () => {
        setLoading(true);
        HttpService.post("/AccountAPI/Login", {
          email: email,
          password: password
        }).then(res => {
          if(res.status !== 200) {
            localStorage.removeItem('token')
            setLoginMessage("Bitte prüfen Sie den Benutzernamen und/oder das Passwort.")          
          } else {
            console.log(res.data)
            localStorage.setItem('token', res.data.value);
            onLogin();
          }
          setLoading(false);
        })
      }

      const handleForgotPassword = () => {
        setLoading(true);
        HttpService.get("/AccountAPI/ForgotPassword", {
          params: { email: email }
          }).then(res => {if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(res.data);
          }
          setLoading(false);
          setType(Type.login)
          setLoginMessage("Es wurde eine E-Mail mit weiteren Informationen an die angegebene E-Mail Adresse gesendet.")
        });    
      }

      const handleResetPassword = () => {
        setLoading(true)
        HttpService.post("/AccountAPI/ResetPassword", {
          email: emailParam,
          password: password,
          confirmPassword: confirmPassword,
          code: codeParam
        }).then(res => {
          if(res.status !== 200) {
            localStorage.removeItem('token')
            setLoginMessage("Passwort konnte nicht zurückgesetzt werden.")          
          } else {
            console.log(res.data)
            localStorage.setItem('token', res.data.value);
            onLogin();
          }
          setLoading(false);
        })
      }

      return (
          <Dialog open={open} fullWidth={true}>
            {type === Type.login && (
              <Box>
                <DialogTitle>Login</DialogTitle>
                {loading && (
                  <DialogProgressComponent delay={1000} />
                )}
                <DialogContent>
                  <DialogContentText>
                    {loginMessage}
                  </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                    />
                    <Link onClick={e => setType(Type.forgotPassword)} tabIndex={0} component="button">
                      Passwort vergessen?
                    </Link>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Abbrechen</Button>
                  <Button onClick={handleLogin} disabled={loading}>Ok</Button>
                </DialogActions>
              </Box>
            )}
            {type === Type.forgotPassword && (
              <Box>
                <DialogTitle>Bitte E-Mail Adresse eingeben</DialogTitle>
                {loading && (
                  <DialogProgressComponent delay={1000} />
                )}
                <DialogContent>
                  <DialogContentText>
                    {loginMessage}
                  </DialogContentText>
                  <TextField
                      autoFocus
                      margin="dense"
                      id="email"
                      label="Email Address"
                      type="email"
                      fullWidth
                      variant="standard"
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Abbrechen</Button>
                  <Button onClick={handleForgotPassword} disabled={loading}>Ok</Button>
                </DialogActions>
              </Box>
            )}
            {type === Type.resetPassword && (
              <Box>
                <DialogTitle>Geben Sie ein neues Passwort ein.</DialogTitle>
                {loading && (
                  <DialogProgressComponent delay={1000} />
                )}
                <DialogContent>
                    <DialogContentText>
                        {loginMessage}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="resetPassword1"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                    />
                    <TextField    
                        margin="dense"
                        id="resetPassword2"
                        label="Password wiederholen"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={e => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                    />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Abbrechen</Button>
                  <Button onClick={handleResetPassword} disabled={loading || password.length === 0 || confirmPassword.length === 0 ||  password !== confirmPassword}>Ok</Button>
                </DialogActions>
              </Box>
            )}
          </Dialog>
      );
}

enum Type {
  login = 0,
  forgotPassword = 1,
  resetPassword = 2
}
