import { Help } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";
import React from "react";

export interface ConfirmationDialogRawProps {
    children?:React.ReactNode;
    title:string;
    open: boolean;
    onCancel: () => void;
    onYes: () => void;
    onNo: () => void;
  }

function ConformationDialog(props: ConfirmationDialogRawProps) {
    const { onCancel, open, onYes, onNo, ...other } = props;
    
    const handleCancel = () => {
        onCancel();
    }

    const handleYes = () => {
        onYes();
    }
    const handleNo = () => {
        onNo();
    }

    return (
        <ConfirmDialog
            open={open}
            {...other}
        > 
            <DialogTitle>
                <Box sx={{float:'left', marginRight: '10px'}}>
                    <Help sx={{fontSize: '2rem', color: 'cornflowerblue'}} />
                </Box>
                <Box>{props.title}</Box>
            </DialogTitle>
            <DialogContent dividers>
            <Box>{props.children}</Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleYes}>
                    Ja
                </Button>
                <Button onClick={handleNo}>
                    Nein
                </Button>
                <Button onClick={handleCancel}>
                    Abbrechen
                </Button>
            </DialogActions>
        </ConfirmDialog>
    )
}
export default ConformationDialog

const ConfirmDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(90% - 10px)',
        width: 'calc(90% - 10px)',
    }
}))