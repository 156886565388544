import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Account } from './types/Common';
import { Saison, UserSaisonAlloc } from './types/Saison'
import ResponsiveDrawer from './components/common/Drawer';
import HttpService from './services/HttpService';
import LoginDialog from './components/common/LoginDialog';
import {BrowserRouter as Router} from 'react-router-dom';
import {Alert, Box, Button, Fade, IconButton, Snackbar } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { useServiceWorker } from './useServiceWorker';
import { MatchDay } from './types/MatchDay';
import { CookiesProvider } from 'react-cookie';
import { ReactComponent as Logo } from './res/images/Logo.svg'

export const AppContext = React.createContext<AppContextInterface | null>(null);

const theme = createTheme({
  
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#252526',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    // MuiBottomNavigationAction: {
    //   styleOverrides: {
    //     label: {
    //       fontSize: '1rem',
    //       selected: {
    //         fontSize: '2rem',
    //         color: 'red'
    //       }
    //     }
    //   }
    // },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        },
        head: {
          fontWeight: 'bold',
          paddingLeft: 6,
          paddingRight: 6,
        },
        body: {
          paddingLeft: 6,
          paddingRight: 6
        }
      }
    },
  } 

});

function App() {

  const [account, setAccount] = React.useState<Account>();
  const [userSaisonAlloc, setUserSaisonAlloc] = React.useState<UserSaisonAlloc>();
  const [saison, setSaison] = React.useState<Saison>();
  const [openLoginDialog, setOpenLoginDialog] = React.useState<boolean>(false);
  const [loaded, isLoaded] = React.useState<boolean>(false);
  const [showUpdateMessage, setShowUpdateMessage] = React.useState(false);
  const [showNoServiceMessage, setShowNoServiceMessage] = React.useState(false);
  const [currentMatchDay, setCurrentMatchDay] = React.useState<MatchDay>();
  const [matchDayEventIntervalId, setMatchDayEventIntervalId] = React.useState(0);

  const AppContextInterface: AppContextInterface = {
    account: account,
    setAccount: setAccount,
    userSaisonAlloc: userSaisonAlloc,
    setUserSaisonAlloc: setUserSaisonAlloc,
    saison: saison,
    setSaison: setSaison,
    currentMatchDay: currentMatchDay,
    setCurrentMatchDay: setCurrentMatchDay,
    matchDayEventIntervalId: matchDayEventIntervalId,
    setMatchDayEventIntervalId: setMatchDayEventIntervalId
  };

  const [reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  React.useEffect(function() {

      // TITLE MIT TEST SERVER ANZEIGEN
      if(HttpService.baseUrl.includes("localhost")) {
        document.title = `${document.title} (TEST SERVER)`
      }

      if(localStorage.getItem('token') !== null) {
        HttpService.get("AccountAPI/UserInformations").then(res => {
          
          isLoaded(true);
          if(res.status === 200) {
            if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              console.log(res.data);
            }
            const user:Account = res.data.user;
            user.roles = res.data.roles;
            user.avatarBase64Thumb = res.data.avatarBase64Thumb;

            // Wenn der Token kurz vor dem Ablauf ist, aktivieren. 

            if(res.data.token) {
              localStorage.removeItem('token');
              localStorage.setItem('token', res.data.token);
            }

            setAccount(user)
            HttpService.get("AccountAPI/Heartbeat")
            setInterval(() => {
              HttpService.get("AccountAPI/Heartbeat")
            }, 30000)

            // Notification.requestPermission(result => {
            //   if(result !== "granted") {
            //     console.log("No notification permission granted!");
            //   } else {
            //     WebPushService.getSubscription().then((sub) => {
            //       if(sub === null) {
            //         WebPushService.subscribe().then((newSub) => {

            //           console.debug("new Sub", newSub)

            //           // Erst aktivieren, wenn Backend fertig ist.
            //           // HttpService.post("AccountAPI/Subscription", {
            //           //   Endpoint: newSub.endpoint
            //           // })
            //         })
            //       } else {
            //         console.log("sub", sub)
            //       }
            //     })
            //   }
            // })

          }
          else {
            handleOpenLoginDialog();
          }
        }).catch(error => {
          setShowNoServiceMessage(true)
        })
      }
      else {
        isLoaded(true)
        handleOpenLoginDialog();
      }
  },[reducerValue])

  React.useEffect(function() {
    if (showReload && waitingWorker) {
      setShowUpdateMessage(true);
    }
    //setShowUpdateMessage(true);
  }, [waitingWorker, showReload, reloadPage]);

  const handleOpenLoginDialog = () => {
      setOpenLoginDialog(true);
  };

  const handleCloseLoginDialog = (value: any) => {
      setOpenLoginDialog(false);
  };

  const handleLogin = () => {
    setOpenLoginDialog(false);
    forceUpdate();
  }
  const handleLogout= () => {
    setAccount(undefined)
    localStorage.removeItem('token')
  }

  const reload = () => {
    window.location.reload()
  }

  const action = (
    // <React.Fragment>
    //   <Button sx={{color: "#2e7d32"}} size="small" onClick={() => reloadPage()}>
    //     UPDATE
    //   </Button>
    // </React.Fragment>
        <React.Fragment>
        <Button sx={{color: "#2e7d32"}} size="small" onClick={reload}>
          UPDATE
        </Button>
      </React.Fragment>
  );

  return (
    <div>
      {loaded && (
        <AppContext.Provider value={AppContextInterface}>
          <ThemeProvider theme={theme}>
            <CookiesProvider>
                <Router>
                  <ResponsiveDrawer onLogout={handleLogout} onLogin={handleOpenLoginDialog} account={account} />
                  <LoginDialog open={openLoginDialog} onClose={handleCloseLoginDialog} onLogin={handleLogin} />
                  <Snackbar
                    open={showUpdateMessage}
                    sx={{marginBottom: {xs: '60px', md: '10px'}}}
                    message="Ein neues Update ist verfügbar!"
                    action={action}
                  />
                </Router>
              </CookiesProvider>
          </ThemeProvider>
        </AppContext.Provider>
      )}
    {!loaded && (
      <Fade in >
        <Box className='shimmer-new' textAlign={'center'} height={"200px"} justifyContent={'center'} paddingTop={'200px'}>
          {/* <Typography fontWeight='bold' sx={{fontSize:{xs:'3rem', md:'5rem'}, lineHeight:{xs:'40px', md:'50px'}}}>
              MANAGER
          </Typography>
          <Typography 
              sx={
                  {
                    marginTop:'0px',
                    marginLeft:{xs: '2.8rem', md: '5rem'},
                    fontSize:{xs: '1.23rem', md: '2rem'},
                    letterSpacing:{xs: '2.4em', md: '2.5em'}
                  }}>
              SPIEL
          </Typography> */}

          <Box className='fadeIn' sx={{height: {xs: '4rem', md: '5.5rem'}, marginBottom: '1rem'}}>
            <Logo height="100%" width="100%" opacity={0.8} />
          </Box>
          
          <Box className="soccer-loader fadeIn">
                      <svg viewBox="0 0 866 866" xmlns="http://www.w3.org/2000/svg">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.83 151.5">
                            <path className="path-0" d="M117.24,69.24A8,8,0,0,0,115.67,67c-4.88-4-9.8-7.89-14.86-11.62A4.93,4.93,0,0,0,96.93,55c-5.76,1.89-11.4,4.17-17.18,6a4.36,4.36,0,0,0-3.42,4.12c-1,6.89-2.1,13.76-3,20.66a4,4,0,0,0,1,3.07c5.12,4.36,10.39,8.61,15.68,12.76a3.62,3.62,0,0,0,2.92.75c6.29-2.66,12.52-5.47,18.71-8.36a3.49,3.49,0,0,0,1.68-2.19c1.34-7.25,2.54-14.55,3.9-22.58Z"
                                fill="#fff" />
                            <path className="path-1" d="M97.55,38.68A43.76,43.76,0,0,1,98,33.44c.41-2.36-.5-3.57-2.57-4.64C91.1,26.59,87,24,82.66,21.82a6.18,6.18,0,0,0-4-.71C73.45,22.55,68.32,24.25,63.22,26c-3.63,1.21-6.08,3.35-5.76,7.69a26.67,26.67,0,0,1-.6,4.92c-1.08,8.06-1.08,8.08,5.86,11.92,3.95,2.19,7.82,5.75,11.94,6.08s8.76-2.41,13.12-3.93c9.33-3.29,9.33-3.3,9.78-14Z"
                                fill="#fff" />
                            <path className="path-2" d="M66.11,126.56c5.91-.91,11.37-1.7,16.81-2.71a3.3,3.3,0,0,0,1.87-2.17c1-4.06,1.73-8.19,2.84-12.24.54-2-.11-3-1.55-4.15-5-4-9.9-8.12-15-12a6.19,6.19,0,0,0-4.15-1.1c-5.35.66-10.7,1.54-16,2.54A4,4,0,0,0,48.34,97a109.13,109.13,0,0,0-3,12.19,4.47,4.47,0,0,0,1.34,3.6c5.54,4.36,11.23,8.53,16.91,12.69a10.84,10.84,0,0,0,2.57,1.11Z"
                                fill="#fff" />
                            <path className="path-3" d="M127.42,104.12c4.1-2.1,8-3.93,11.72-6a6,6,0,0,0,2.27-3,58.22,58.22,0,0,0,3.18-29.92c-.26-1.7-8-7.28-9.71-6.85A5,5,0,0,0,133,59.65c-2.81,2.49-5.71,4.88-8.33,7.56a9.46,9.46,0,0,0-2.47,4.4c-1.29,6.49-2.38,13-3.35,19.55a5.73,5.73,0,0,0,.83,3.91c2.31,3.08,5,5.88,7.7,9Z"
                                fill="#fff" />
                            <path className="path-4" d="M52.58,29.89c-2.15-.36-3.78-.54-5.39-.9-2.83-.64-4.92.1-7,2.32A64.1,64.1,0,0,0,26.09,54.64c-2.64,7.92-2.62,7.84,5.15,10.87,1.76.69,2.73.45,3.93-1C39.79,59,44.54,53.65,49.22,48.2a4.2,4.2,0,0,0,1.13-2c.8-5.32,1.49-10.68,2.24-16.34Z"
                                fill="#fff" />
                            <path className="path-5" fill="#fff" d="M23,68.13c0,2.51,0,4.7,0,6.87a60.49,60.49,0,0,0,9.75,32.15c1.37,2.13,6.4,3,7,1.2,1.55-5,2.68-10.2,3.82-15.34.13-.58-.58-1.38-.94-2.06-2.51-4.77-5.47-9.38-7.45-14.37C32.94,71,28.22,69.84,23,68.13Z" />
                            <path className="path-6" fill="#fff" d="M83.91,12.86c-.32.36-.66.71-1,1.07.9,1.13,1.57,2.62,2.73,3.33,4.71,2.84,9.56,5.48,14.39,8.1a9.29,9.29,0,0,0,3.13.83c5.45.69,10.89,1.38,16.35,1.94a10.41,10.41,0,0,0,3.07-.71c-11.48-9.9-24.26-14.61-38.71-14.56Z"
                            />
                            <path className="path-7" fill="#fff" d="M66.28,132.51c13.36,3.78,25.62,3.5,38-.9C91.68,129.59,79.36,128,66.28,132.51Z" />
                            <path className="path-8" fill="#fff" d="M127.2,30.66l-1.27.37a18.58,18.58,0,0,0,1,3.08c3,5.52,6.21,10.89,8.89,16.54,1.34,2.83,3.41,3.82,6.49,4.9a60.38,60.38,0,0,0-15.12-24.9Z" />
                            <path className="bb-9" fill="#fff" d="M117.35,125c5.58-2.32,16.9-13.84,18.1-19.2-2.41,1.46-5.18,2.36-6.78,4.23-4.21,5-7.89,10.37-11.32,15Z" />
                        </svg>
                    </svg>
              </Box>
              <Snackbar 
                open={showNoServiceMessage} 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert 
                  severity="error"
                  action={<IconButton onClick={reloadPage}><Refresh /></IconButton>}
                  >
                  Server aktuell nicht erreichbar. Bitte später nochmal versuchen.
                </Alert>
              </Snackbar>
        </Box>
      </Fade>
    )}
   </div>
  );
}

export default App;

interface AppContextInterface {
  account: Account | undefined;
  setAccount: React.Dispatch<React.SetStateAction<Account | undefined>> | undefined;
  userSaisonAlloc: UserSaisonAlloc | undefined;
  setUserSaisonAlloc: React.Dispatch<React.SetStateAction<UserSaisonAlloc | undefined>>;
  saison: Saison | undefined;
  setSaison: React.Dispatch<React.SetStateAction<Saison | undefined>>;
  currentMatchDay: MatchDay | undefined;
  setCurrentMatchDay: React.Dispatch<React.SetStateAction<MatchDay | undefined>>;
  matchDayEventIntervalId: number;
  setMatchDayEventIntervalId: React.Dispatch<React.SetStateAction<number>>;
}