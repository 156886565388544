import { Box, Button, Card, CardHeader, Fab, Fade } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import React from "react";
import HttpService from "../services/HttpService";
import { AppContext } from "../App";
import { AllocRole, SaisonState, TeamPlayerPosition, TeamPlayerSuggest, TeamPlayerSuggestsModel } from "../types/Saison";
import { Add, EditOutlined, EuroOutlined, FileDownload, Upload } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import TeamPlayerSuggestDialogComponent from "../components/saison/TeamPlayerSuggestDialogComponent";
import TeamPlayerSuggestTransferDialogComponent from "../components/saison/TeamPlayerSuggestTransferDialogComponent";
import Loader from "../components/common/Loader";
import ShirtComponent from "../components/common/ShirtComponent";
import TeamPlayerSuggestConfirmImportDialogComponent from "../components/saison/TeamPlayerSuggestConfirmImportDialogComponent";

const TeamPlayerSuggestsPage = () => {

    let { id } = useParams();

    const [ reducerValue, forceUpdate ] = React.useReducer(x => x + 1, 0);
    const [ teamPlayerSuggestsModel, setTeamPlayerSuggestsModel ] = React.useState<TeamPlayerSuggestsModel>()
    const [ openDialog, setOpenDialog ] = React.useState(false);
    const [ selectedTeamPlayerSuggest, setSelectedTeamPlayerSuggest ] = React.useState<TeamPlayerSuggest>();
    const [ teamPlayerSuggestToTransfer, setTeamPlayerSuggestToTransfer] = React.useState<TeamPlayerSuggest>()
    const [ openTransferDialog, setOpenTransferDialog   ] = React.useState(false);
    const [ openConfirmImportDialog, setOpenConfirmImportDialog ] = React.useState(false);

    const ctx = React.useContext(AppContext)

    React.useEffect(function() {

        window.scrollTo(0, 0);

        HttpService.get('/SaisonAPI/TeamPlayerSuggests', {
            params: { saisonId: id }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                } 
                
                ctx?.setUserSaisonAlloc(res.data.userSaisonAlloc)
                ctx?.setSaison(res.data.saison)
                
                setTeamPlayerSuggestsModel(res.data)
            });
      },[id, reducerValue])

    const columns: GridColDef[] = [
        { field: 'position', headerName: 'POSITION', flex: 0.5, type:"singleSelect", valueOptions: [{label: 'TOR', value:TeamPlayerPosition.goalkeeper}, {label: 'ABWEHR', value:TeamPlayerPosition.defender }, {label: 'MITTELFELD', value:TeamPlayerPosition.midfielder}, {label: 'STURM', value:TeamPlayerPosition.striker}], valueFormatter: (param) => {
            switch (param) {
                case TeamPlayerPosition.goalkeeper:
                    return "TOR"
                case TeamPlayerPosition.defender:
                    return "ABW"
                case TeamPlayerPosition.midfielder:
                    return "MIT"
                case TeamPlayerPosition.striker:
                    return "ANG"
                default:
                    break;
            }
        }, renderCell: (params) => {
            // return <PositionIcon position={params.value}/>
            return <ShirtComponent teamPlayerPosition={params.value} number={params.row.number}/>
        }},
        { field: 'name', headerName: 'NAME', flex: 1, renderCell: (params) => {
            return (<Box sx={params.row.teamPlayerId ? {textDecoration: 'line-through'} : {}}>{params.value}</Box>)
        } },
        { field: 'teamId', headerName: 'VEREIN', flex: 0.5, type:"singleSelect", valueOptions: teamPlayerSuggestsModel?.teams.sort((a,b) => a.name.localeCompare(b.name)).map(function(s) { return {value: s.id, label: s.name}}), renderCell: (params) => {
            return (
                <Box>
                {params.value && (
                <Box className='teamlogo-fit' height={30} minWidth={50} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + teamPlayerSuggestsModel?.teams.find(s => s.id === params.value)?.imageLogoPath + ')'}} />
                )}
                </Box>
            );}, valueFormatter: (param) => {
                    return teamPlayerSuggestsModel?.teams.find(s => s.id === param)?.shortName
            }
            },
        { field: 'kickerPrice', headerName: 'PREIS', filterable: true, flex: 0.5, renderCell(params) {
            return params.value
        },valueFormatter : (param) => {
            return param + " Mio"
        }}
    ]

    if(teamPlayerSuggestsModel?.userSaisonAlloc.allocRole === AllocRole.owner && teamPlayerSuggestsModel.saison.state !== SaisonState.Finished) {
        columns.push({ field: 'id', headerName: '...', flex: 1, filterable: false, sortable: false, renderCell: (params) => {
            return (
                teamPlayerSuggestsModel?.userSaisonAlloc.allocRole === AllocRole.owner && teamPlayerSuggestsModel.saison.state !== SaisonState.Finished && (
                <Box>
                    <Button sx={{padding: '0px', minWidth: '20px'}} onClick={() => {
                        handleEdit(params.value) 
                        }}>
                        <EditOutlined />
                    </Button>
                    <Button disabled={params.row.teamPlayerId !== null} sx={{padding: '0px'}} onClick={() => {
                        handleTransfer(params.value) 
                        }}>
                        <EuroOutlined />
                    </Button>
                </Box>
                )       
            )
        }})
    }

    const handleEdit = (TeamPlayerSuggestId:string) => {
        const TeamPlayerSuggest = teamPlayerSuggestsModel?.teamPlayerSuggests.find(s => s.id === TeamPlayerSuggestId)
        if(TeamPlayerSuggest) {
            setSelectedTeamPlayerSuggest(TeamPlayerSuggest)
            setOpenDialog(true)
        }
    }

    const handleCreateTeamPlayerSuggest = () => {
        setSelectedTeamPlayerSuggest(undefined)
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
        forceUpdate();
    }

    const handleTransfer = (TeamPlayerSuggestId:string) => {
        const TeamPlayerSuggest = teamPlayerSuggestsModel?.teamPlayerSuggests.find(s => s.id === TeamPlayerSuggestId)
        if(TeamPlayerSuggest) {
            setTeamPlayerSuggestToTransfer(TeamPlayerSuggest);
            setOpenTransferDialog(true);
        }
    }

    const handleCloseTransferDialog = () => {
        setOpenTransferDialog(false)
        forceUpdate();
    }

    

    const handleCloseConfirmImportDialog = () => {
        setOpenConfirmImportDialog(false)
    }

    const handleSaveConfirmImportDialog = () => {
        setOpenConfirmImportDialog(false)
        forceUpdate();
    }

    const apiRef = useGridApiRef();

    const exportCSV = () => {
        apiRef.current.exportDataAsCsv({
            fileName: 'ManagerSpiel - Spielerpool',
            fields: ["name", "teamId", "position", "kickerPrice"],
            delimiter: ';',
            utf8WithBom: true
        });
    }

    return (
        <div>
        {teamPlayerSuggestsModel && (
            <Fade in>
                <Card>
                    <CardHeader title="SPIELERPOOL" />
                    {teamPlayerSuggestsModel?.teamPlayerSuggests && (
                    <Box>
                        <Box sx={{ width: '100%'}} paddingBottom={6}>
                            <DataGrid
                                sx={{fontSize: '1rem'}}
                                apiRef={apiRef}
                                rowHeight={35}
                                autoHeight
                                
                                loading={!teamPlayerSuggestsModel}
                                rows={teamPlayerSuggestsModel?.teamPlayerSuggests}
                                
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: {
                                    pageSize: 50,
                                    },
                                },
                                }}
                                pageSizeOptions={[50]}
                                disableRowSelectionOnClick
                                checkboxSelection
                                disableColumnSelector
                                disableDensitySelector
                                slots={{
                                    toolbar: GridToolbar
                                }}
                                slotProps={{
                                    toolbar: { 
                                        showQuickFilter: true,
                                        quickFilterProps: {debounceMs: 500},
                                        printOptions: { disableToolbarButton: true },
                                        csvOptions: { 
                                            disableToolbarButton: true,  
                                            fileName: 'ManagerSpiel - Spielerpool',
                                            fields: ["name", "teamId", "position", "kickerPrice"],
                                            delimiter: ';',
                                            utf8WithBom: true
                                        }
                                    }
                                }}
                            /> 
                            <Button sx={{margin: '5px'}} onClick={exportCSV} startIcon={<FileDownload />}>
                                Export/CSV
                            </Button>
                            {/* <Button sx={{margin: '5px'}} onClick ={deleteSelection} startIcon={<Delete />}>Löschen</Button> */}
                            {teamPlayerSuggestsModel.userSaisonAlloc.allocRole === AllocRole.owner && teamPlayerSuggestsModel.saison.state !== SaisonState.Finished && (
                                <Box>
                                    <Box>
                                        {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && 
                                            //NOCH NICHT VERÖFFENTLICHT
                                            <Fab color="primary" aria-label="add" size="small" onClick={() => setOpenConfirmImportDialog(true)} component="label" sx={{position: 'fixed',  bottom: (ctx?.saison ? {xs: '116px', md: '66px'} : '66px'), right: '16px'}}>
                                                <Upload />
                                                {/* <input
                                                    type="file"
                                                    accept=".csv"
                                                    hidden
                                                    onChange={handleImportTeamPlayerSuggests}
                                                /> */}
                                            </Fab>
                                        }
                                    </Box>
                                    <Box>
                                        <Fab color="primary" aria-label="add" size="small" onClick={handleCreateTeamPlayerSuggest} sx={{position: 'fixed',  bottom: (ctx?.saison ? {xs: '66px', md: '16px'} : '16px'), right: '16px'}}>
                                            <Add />
                                        </Fab>
                                    </Box>
                                </Box>
                            )}
                            <TeamPlayerSuggestDialogComponent open={openDialog} onClose={handleCloseDialog} teams={teamPlayerSuggestsModel.teams} teamPlayerSuggest={selectedTeamPlayerSuggest}/>
                            <TeamPlayerSuggestTransferDialogComponent 
                                onClose={handleCloseTransferDialog} 
                                open={openTransferDialog} 
                                team={teamPlayerSuggestsModel.teams.find(s => s.id === teamPlayerSuggestToTransfer?.teamId)} 
                                teamPlayerSuggest={teamPlayerSuggestToTransfer}
                                userSaisonAllocs={teamPlayerSuggestsModel.userSaisonAllocs}
                            />
                            <TeamPlayerSuggestConfirmImportDialogComponent
                                open={openConfirmImportDialog}
                                saisonId={teamPlayerSuggestsModel.saison.id}
                                onCancel={handleCloseConfirmImportDialog}
                                onSave={handleSaveConfirmImportDialog}
                            />
                        </Box>
                    </Box>
                    )}
                    <iframe id="ifmcontentstoprint" title="unique" style={{height: "0px", width: "0px", position: "absolute"}}></iframe>
                </Card>
            </Fade>
        )}
        {!teamPlayerSuggestsModel && (
            <Loader delay={1500}>Spielerpool wird geladen...</Loader>
        )}
        </div>
    )
}

export default TeamPlayerSuggestsPage